// A Table for instances that are archived and unarchived

import { PropTypes } from 'react';
import Subheader from 'material-ui/Subheader';
import SearchIcon from 'material-ui/svg-icons/action/search';
import TextField from 'material-ui/TextField';
import EntriesIcon from 'material-ui/svg-icons/notification/event-note';
import EntryIcon from 'material-ui/svg-icons/action/event';
import AddCircle from 'material-ui/svg-icons/content/add-circle';
import {Card, CardActions, CardHeader, CardText} from 'material-ui/Card';
import { grey300, lightBlack, green500 } from 'material-ui/styles/colors';
import { blue500 } from 'material-ui/styles/colors';
import { blue300 } from 'material-ui/styles/colors';

// Styles
//------------------------------------------------------------------------------
const headerStyles = {
  color: lightBlack,
  fontSize: '18px'
};

const miniIconStyles = {
  height: '35px',
  width: '35px',
  position: 'relative',
  top: '10px',
  left: '-5px'
};

const inlineSearchStyles = {
  float: 'right',
  textAlign: 'right',
  fontWeight: 400,
  marginRight: '1em'
};

// Component
//------------------------------------------------------------------------------
const EntriesList = ({ entries, instanceInputOnChange }) => {
  return (<div className="Entries List">
    <Subheader style={headerStyles}>
      <EntriesIcon color={green500} style={miniIconStyles} />
      Lottry Entries
      <TextField
        onChange={instanceInputOnChange}
        style={inlineSearchStyles}
        underlineFocusStyle= {{ borderColor: green500 }}
        hintText={<span>
          <SearchIcon
            color={grey300}
            style={{
              height: '15px',
              width: '15px',
              position: 'relative',
              top: '2px',
              left: '-2px'
            }}
          />
        Find Lottery Entry
        </span>}
      />
    </Subheader>
    <div className="Entries">
      { entries.map( (entry) => {
        const { data:fields, id, path, new_path } = entry;

        return (
          <Card
            key={`entry-${id}`}
            containerStyle={{ margin: '1.5rem 0 ' }}
          >
          <CardHeader
            title={<h4>
                <a href={path} style={{color: green500}}>{entry.fillable.name}</a>
              </h4>}
            avatar={<EntryIcon color={green500} style={miniIconStyles} />}
            subtitle={`Created: ${entry.created_at}`}
          />
        <hr style={{ margin: '-1rem 1rem 0' }}/>
          <CardText>
            <dl className="Fields">
            {
              Object.keys(fields).map((key) => {
                const field  = fields[key];
                const {display, name, value} = field;

                if(!display) { return null; }
                return (
                  <div className="Field" key={`${key}-${id}`}>
                    <b>{name}:</b>
                    {"  "}
                    <span className="value">{value}</span>
                  </div>
                );
              })
            }
            </dl>
            <AddCircle
              color={blue500}
              style={{
                height: '15px',
                width: '15px',
                position: 'relative',
                top: '2px',
                left: '-2px'
              }}
            />
            <a href={new_path} color={blue300}>
              {`${entry.fillable.name} Entry`}
            </a>
          </CardText>
          <CardActions>
            Actions!
          </CardActions>
        </Card>);
      })
      }
    </div>
  </div>);
};

EntriesList.propTypes = {
  instanceInputOnChange: PropTypes.func,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      fillable: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string
      }),
      path: PropTypes.string.isRequired,
      new_path: PropTypes.string
    })
  )
};

EntriesList.defaultProps = {
  hideNewInstanceButton: false,
  hideTable: false,
};


export default EntriesList;
