export function increasePage(){
  return {type: 'INCREASE_PAGE' };
}

export function decreasePage(){
  return {type: 'DECREASE_PAGE' };
}

export function setItemsPerPage(itemsPerPage){
  return {type: 'SET_ITEMS_PER_PAGE', itemsPerPage };
}

export function setCurrentPage( currentPage ){
  return {type: 'SET_CURRENT_PAGE', currentPage };
}

export function resetPager(){
  return {type: 'SET_CURRENT_PAGE', currentPage: 0 };
}

export function setMaxPage(maxPage){
  return {type: 'SET_MAX_PAGE', maxPage };
}
