import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { muiTheme } from '../utils/utils';
import {Tabs, Tab} from 'material-ui/Tabs';
import FillableFilter from './fillable_filter';
import RecentActivityTab from './recent_activity_tab';

const OrganizationFillables = () => (
  <MuiThemeProvider muiTheme={muiTheme}>
    <Tabs>
      <Tab label="Recent Activity">
        <RecentActivityTab />
      </Tab>
      <Tab label="Find A Fillable">
        <FillableFilter />
      </Tab>
    </Tabs>
  </MuiThemeProvider>
);

export default OrganizationFillables;
