import { PropTypes } from 'react';
import { Button } from 'react-bootstrap';

const RoleButton = ({ title, role, type, id, value, onPress }) => (
  <Button
    active={ role === value }
    bsSize="small"
    bsStyle={type}
    onClick={() => onPress(id, value)}
  >
  {title}
  </Button>
);

RoleButton.propTypes = {
  title:   PropTypes.string.isRequired,
  id:      PropTypes.number.isRequired,
  role:    PropTypes.string,
  value:   PropTypes.string.isRequired,
  type:   PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default RoleButton;
