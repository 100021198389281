import { connect } from 'react-redux';
import GroupTable from './group_table';
import { updateGroupFilter, setGroupsSelected } from '../../actions/user_role_actions';
import AutoComplete from 'material-ui/AutoComplete';

const { fuzzyFilter } = AutoComplete;

const getVisibleGroups = (groups, filter) => {
  if(typeof filter === 'undefined' || filter === ''){ return groups; }

  return  groups.filter((group) => fuzzyFilter(filter, group.name || ''));
};

const mapStateToProps = (state) => {
  const { list, filter, selected} = state.groups;
  return {
    filter,
    selected,
    groups: getVisibleGroups(list, filter)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterOnChange: (e) => {
      const filterText = e.target.value;
      dispatch(updateGroupFilter(filterText));
    },
    rowOnSelect: (selectedRowIndexes) => {
      dispatch(setGroupsSelected(selectedRowIndexes));
    }
  };
};

const VisibleGroupTable = connect(mapStateToProps, mapDispatchToProps)(GroupTable);

export default VisibleGroupTable;
