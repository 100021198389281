import { connect } from 'react-redux';
import GroupTable from './group_table';
import { setGroupRole } from '../../actions/user_role_actions';
import AutoComplete from 'material-ui/AutoComplete';

const { fuzzyFilter } = AutoComplete;

const getVisibleGroups = (groups, filter) => {
  if(typeof filter === 'undefined' || filter === ''){ return groups; }

  return  groups.filter((group) => fuzzyFilter(filter, group.name || ''));
};

const mapStateToProps = (state) => {
  const { groups, groupsFilter} = state;
  return { groups: getVisibleGroups(groups, groupsFilter) };
};

const mapDispatchToProps = (dispatch) => {
  return {
    roleOnChange: (id, key) => {
      const roles = ['owner', 'editor', 'viewer', null];
      dispatch(setGroupRole(id, roles[key]));
    }
  };
};

const VisibleGroupTable = connect(mapStateToProps, mapDispatchToProps)(GroupTable);

export default VisibleGroupTable;
