import getMuiTheme from 'material-ui/styles/getMuiTheme';
import React, {Component, PropTypes} from 'react';
import { grey50, blue400, blue700, deepOrange400, deepOrange700 } from 'material-ui/styles/colors';

export const muiTheme = getMuiTheme({
  palette: {
    primary1Color: blue400,
    primary2Color: deepOrange700,
    accent1Color: deepOrange400,
    pickerHeaderColor: blue400,
  }
});

// Add csrf-token to fetch for rails
// See https://github.com/shakacode/react_on_rails/issues/16#issuecomment-220750318

/**
 *
 * A regular non-safe get request:
 * fetch('/profiles/foobar.json', jsonHeader());
 *
 * How this would look in a safe fetch request:
 * fetch('/profiles.json', safeCredentials({
 *              method: 'POST',
 *              body: JSON.stringify({
 *                  q: input,
 *                  thing: this.props.thing
 *              })
 *          }));
 *
 *
 */

/**
 * For use with window.fetch
 */
export function jsonHeader (options) {
  options = options || {};
  return Object.assign(options, {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  });
}

/**
 * Lets fetch include credentials in the request. This includes cookies and other possibly sensitive data.
 * Note: Never use for requests across (untrusted) domains.
 */
export function safeCredentials (options) {
  options = options || {};
  return Object.assign(options, {
    credentials: 'include',
    mode: 'same-origin',
    headers: Object.assign((options['headers'] || {}), authenticityHeader(), jsonHeader())
  });
}

// Additional helper methods

export function authenticityHeader (options) {
  options = options || {};
  return Object.assign(options, {
    'X-CSRF-Token': getAuthenticityToken(),
    'X-Requested-With': 'XMLHttpRequest'
  });
}

export function getAuthenticityToken () {
  return getMetaContent('csrf-token');
}

export function getMetaContent (name) {
  const header = document.querySelector(`meta[name="${name}"]`);
  return header && header.content;
}

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export function wrapState(ComposedComponent, selectedItemStyle = {}) {
  return class SelectableList extends Component {
    static propTypes = {
      children: PropTypes.node.isRequired,
      defaultValue: PropTypes.number,
    };

    componentWillMount() {
      this.setState({
        selectedIndex: this.props.defaultValue,
      });
    }

    handleRequestChange = (event, index) => {
      this.setState({
        selectedIndex: index,
      });
    };

    render() {
      return (
        <ComposedComponent
          selectedItemStyle={selectedItemStyle}
          value={this.state.selectedIndex}
          onChange={this.handleRequestChange}
        >
          {this.props.children}
        </ComposedComponent>
      );
    }
  };
}
