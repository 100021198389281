import { connect } from 'react-redux';
import { setGroupRole } from '../../actions/user_role_actions';
import RoleButton from './role_button';

const mapStateToProps = (state) => {
  return { filterTerm: state.userFilter };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPress: (id, role) => {
      dispatch(setGroupRole(id, role));
    }
  };
};

const GroupRoleButton = connect(mapStateToProps, mapDispatchToProps)(RoleButton);
export default GroupRoleButton;
