import { PropTypes, Component } from 'react';
import { grey700, red500, green500 } from 'material-ui/styles/colors';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MailIcon from 'material-ui/svg-icons/communication/mail-outline';
import Snackbar from 'material-ui/Snackbar';
import { safeCredentials, checkStatus } from '../utils/utils';

// styles
//------------------------------------------------------------------------------

const btnStyles = {
  fontWeight: 400,
  fontSize: '12px',
  color: grey700,
};


// Component
//------------------------------------------------------------------------------

class RemindApproversBtn extends Component {
  state = {
    open: false,
    snackbarOpen: false,
    snackbarMessage: 'Your Request has been sent',
    snackbarBodyStyle: {  content: { color: green500 }, fontWeight: 500 }
  };

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = ( buttonClicked ) => {
    this.setState({open: false});

    // just exit unless confirm was clicked
    if( !buttonClicked || buttonClicked.target.innerText === 'CANCEL'){
      return false;
    }

    // Send post to controller and notify user for success
    fetch(this.props.url,
      safeCredentials({ method: 'POST' })
    ).then(checkStatus)
  .then( () => {
    this.setState({
      snackbarOpen: true,
    });
  }).catch((error) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: 'There was an error sending your request',
      snackbarBodyStyle: Object.assign(this.state.snackbarBodyStyle,
                                        { color: red500, })
    });
    console.error('request failed', error);
  });
  };

  handleRequestClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    const actions = [
      <FlatButton
        label="Cancel"
        primary={true}
        onTouchTap={this.handleClose}
      />,
      <FlatButton
        label="Notify"
        primary={true}
        keyboardFocused={true}
        onTouchTap={this.handleClose}
      />,
    ];

    return (
      <div className="remind-btn">
        <FlatButton
          style={{
            display: 'inline-block',
            position: 'relative',
            top: '-0.75rem'
          }}
          labelStyle={btnStyles}
          label="Remind Approvers"
          icon={<MailIcon color={grey700} />}
          onTouchTap={this.handleOpen} />
        <Dialog
          title="Remind Approvers"
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          <b style={{color: red500}}>WARNING:  </b>
            Are you sure you want to send reminder emails to all listend approvers?
        </Dialog>
        <Snackbar
          bodyStyle={this.state.snackbarBodyStyle}
          style={this.state.snackbarBodyStyle}
          open={this.state.snackbarOpen}
          message={this.state.snackbarMessage}
          autoHideDuration={500}
          onRequestClose={this.handleRequestClose}
        />
      </div>
    );
  }
}

RemindApproversBtn.propTypes = {
  url: PropTypes.string.isRequired
};

export default RemindApproversBtn;
