"use strict";

class CategoriesContainer extends React.Component {
  render () {
    return <CategorySelect {...this.props} />;
  }
}

CategoriesContainer.defaultProps = {
  arranged: [],
  disabled: false
}

CategoriesContainer.propTypes = {
  arranged: React.PropTypes.array,
  disabled: React.PropTypes.bool
}

class CategorySelect extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      selectedCategory: this._isSelected(this.props.arranged)
    };

    this.handleChange = (event) => {
      this.setState({selectedCategory: event.target.value});
    };
  }

  _isSelected (array) {
    var isSelected = array.find(element =>
      element['selected'] && element['selected'] == true);
    return isSelected ? isSelected['id'] : 'placeholder';
  }

  render () {
    var createOptions = function(obj, index) {
      return <option key={index} value={obj['id']}>{obj['name']}</option>;
    };

    const createSubCategory = function(arranged, disabled, selectedCategory) {
      var id = selectedCategory;
      var selectedObject = arranged.find(
        element =>
        element['id'] == id
      );
      if (selectedObject && selectedObject['children'].length > 0) {
        return (
          <CategorySelect
            disabled={disabled}
            arranged={selectedObject['children']}
          />
        );
      };
    };

    return (
      <div>
        <div className="form-group select optional field_input_type">
          <label
            className="select optional col-sm-2 control-label"
            htmlFor="entry_category_id">
            Category
          </label>
          <div className="col-sm-10">
            <select
              className="select optional form-control"
              disabled={this.props.disabled}
              value={this.state.selectedCategory}
              onChange={this.handleChange}
              name="entry[category_id]"
              id="entry_category_id">
              <option key="placeholder" value="0">Select a Category</option>
              {this.props.arranged.map(createOptions)}
            </select>
          </div>
        </div>
        {createSubCategory(this.props.arranged, this.props.disabled, this.state.selectedCategory)}
      </div>
    );
  }
}

CategorySelect.defaultProps = {
  arranged: [],
  disabled: false
}

CategorySelect.propTypes = {
  arranged: React.PropTypes.array,
  disabled: React.PropTypes.bool

}


module.exports = CategoriesContainer;

