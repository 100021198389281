import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';
import { List, ListItem, makeSelectable } from 'material-ui/List';
import IconButton from 'material-ui/IconButton';
import AddIcon from 'material-ui/svg-icons/content/add';
import AutoComplete from 'material-ui/AutoComplete';
import Avatar from 'material-ui/Avatar';
import Assignment from 'material-ui/svg-icons/action/assignment';
import SearchIcon from 'material-ui/svg-icons/action/search';
import Sort from 'material-ui/svg-icons/content/sort';
import LinkIcon from 'material-ui/svg-icons/content/link';
import FontIcon from 'material-ui/FontIcon';
import Subheader from 'material-ui/Subheader';
import FlatButton from 'material-ui/FlatButton';
import { blue50, blue300, blue500, white, grey50, grey300, lightBlack } from 'material-ui/styles/colors';
import { updateCurrentFillable, setFillableFilter } from '../../actions/fillables_filter_actions';
import { wrapState } from '../../utils/utils';

// Styles
//------------------------------------------------------------------------------
const headerStyles = {
  color: lightBlack,
  fontSize: '18px',
  backgroundColor: grey50
};

const miniIconStyles = {
  height: '20px',
  width: '20px',
};

const inlineSearchStyles = {
  float: 'right',
  textAlign: 'right',
  fontWeight: 400,
  marginRight: '0.5em',
  width: '175px',
};

const selectedItemStyle = {
  backgroundColor: blue50,
  border: 'none'
};

// Component
//------------------------------------------------------------------------------
const SelectableList = wrapState( makeSelectable(List), selectedItemStyle);

const FillableList = ({ selectedFillable, fillables, fillableOnClick,
  fillableInputOnChange, can_create_fillable,
  addButtonOnclick}) => {
  return (
      <SelectableList
        className="FillablesList">
        <Subheader
          className="ListHeader"
          style={headerStyles}>
          <Sort color={blue500} style={miniIconStyles}/>
          Fillables
          {can_create_fillable &&
            <FlatButton
              onClick={addButtonOnclick}
              tooltip="Create New Fillable"
              icon={<AddIcon  />}
              style={{
                float: 'right',
                color: blue500,
                position: 'relative',
                top: '5px',
                minWidth: '50px',
                marginRight: '.5em',
              }}
            />
          }
          <TextField style={inlineSearchStyles}
            className="SearchField"
            onChange={fillableInputOnChange}
            hintText={
              <span>
                <SearchIcon
                  color={grey300}
                  style={{
                    height: '15px',
                    width: '15px',
                    position: 'relative',
                    top: '3px',
                    left: '-2px'
                  }}
                />
              Search Fillables
              </span>
            }
          />
        </Subheader>
        {fillables.map((fillable) => {
          const { id, name, description, rule, path } = fillable;
          const selected = (selectedFillable === id);
          const icon = (rule === 'lottery') ?  <FontIcon className="fa fa-list-alt" /> : <Assignment />;
          return <ListItem
                    value={id}
                    onTouchTap={() => fillableOnClick(id) }
                    key={id}
                    id={id}
                    primaryText={name}
                    secondaryText={description}
                    secondaryTextLines={2}
                    className={`ListItem ${ selected && 'selected' }`}
                    leftAvatar={<Avatar
                        backgroundColor={blue300}
                        color={white}
                        icon={icon}
                      />}
                    rightIconButton={
                      <a href={path}>
                        <IconButton
                          style={{
                            marginTop: '10px',
                            borderRadius: '50%',
                            width: '50px'
                          }}
                          iconStyle={{
                            height: '35px',
                            width: '35px'
                          }} >
                          <LinkIcon color={blue500}/>
                        </IconButton>
                      </a>}
                  />;
        })}
      </SelectableList>
  );
};

FillableList.propTypes = {
  can_create_fillable: PropTypes.bool,
  selectedFillable: PropTypes.number,
  fillableOnClick: PropTypes.func.isRequired,
  addButtonOnclick: PropTypes.func.isRequired,
  fillableInputOnChange: PropTypes.func.isRequired,
  fillables: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      rule: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  )
};


// Redux functions
//------------------------------------------------------------------------------
const mapStateToProps = (state) => {
  const { fillables, can_create_fillable, selectedFillable } = state.data;
  return {
    can_create_fillable,
    selectedFillable,
    fillables: visibleFillables(state.filters.fillables, fillables)
  };
};

const mapDispatchToProps = (dispatch) => (
  {
    fillableInputOnChange: (e) => {
      dispatch(setFillableFilter(e.target.value));
    },
    fillableOnClick: (filterId) => {
      dispatch(updateCurrentFillable(filterId));
      setTimeout(() => {
        const elem = document.querySelector('.FormsList');
        elem && $('html, body').animate({
          scrollTop: $(elem).offset().top
        }, 500);
      }, 200);
    },
    addButtonOnclick: () => {
      // Fire the bootstrap modal
      $('#begin-modal').modal();
    }
  });

const visibleFillables = (filter, fillables) => {
  return fillables.filter( fillable => AutoComplete.fuzzyFilter(filter, fillable.name));
};

export default connect(mapStateToProps,mapDispatchToProps)(FillableList);
