'user strict';

class MultipleFieldGroup extends React.Component{
  constructor (props) {
    super(props);

    const vals = [];

    for (let i = 0; i < this.props.initialFields.length; i++) {
      const key = this.props.initialFields[i][0];
      const value = this.props.initialFields[i][1]['value'];
      const obj = {};
      // Ignore ESlint leave as ==
      obj[key] = value == null ? [''] : value;
      vals.push(obj);
    }

    let fieldGroups = [];

    for (let key in vals[0]) {
      // skip loop if the property is from prototype
      if (!vals[0].hasOwnProperty(key)) { continue; }


      for (let i = 0; i < vals[0][key].length; i++) {
        fieldGroups.push(i);
      }
    }

    this.state = { values: vals, fieldGroups: fieldGroups };

    this.handleAddLink = this.handleAddLink.bind(this);
    this.removeGroup = this.removeGroup.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  removeGroup (index) {
    if (this.props.disabled === true) { return; }
    let fieldGroups = this.state.fieldGroups;
    let values = this.state.values;
    values.map(function(obj,i) {
      for (let key in obj) {
        // skip loop if the property is from prototype
        if (!obj.hasOwnProperty(key)) { continue; }
        obj[key].splice(index,1);
      }
    });
    fieldGroups.pop();
    this.setState({values: values, fieldGroups: fieldGroups});
  }

  handleAddLink () {
    if (this.props.disabled === true) { return; }

    let values = this.state.values;
    let fieldGroups = this.state.fieldGroups;
    values.map(function(obj,i) {
      for (let key in obj) {
        // skip loop if the property is from prototype
        if (!obj.hasOwnProperty(key)) { continue; }
        obj[key].push('');
      }
    });
    fieldGroups.push(fieldGroups.length);
    this.setState({values: values, fieldGroups: fieldGroups});
  }

  updateValue (field, index, value) {
    let values = this.state.values;
    values.map(function(obj,i) {
      if (obj.hasOwnProperty(field)) { obj[field][index] = value; }
    });
    this.setState({values: values});
  }

  render () {
    let values = this.state.values;
    let fieldGroups = this.state.fieldGroups;

    let groups = fieldGroups.map(function(i) {
      return (
        <MultipleWrapper
          key={i}
          index={i}
          values={values}
          handleRemoveLink={this.removeGroup}
          handleUpdate={this.updateValue}
          {...this.props}
        />
      );
    }.bind(this));

    return (
      <div>
        {groups}
        <div className="col-sm-1 col-sm-offset-11">
          <span
            onClick={this.handleAddLink}
            className="edit-link btn btn-primary btn-xs">
            <i className="fa fa-plus"></i>
          </span>
        </div>
      </div>
    );
  }
}

MultipleFieldGroup.defaultProps = {
  model: 'entry',
  modelId:'entry',
  disabled: false,
  initialFields: []
};

MultipleFieldGroup.propTypes = {
  model: React.PropTypes.string,
  modelId: React.PropTypes.string,
  disabled: React.PropTypes.bool,
  initialFields: React.PropTypes.array
};

class MultipleWrapper extends React.Component {
  constructor (props) {
    super(props);

    this.state = { values: this.props.values };

    this.handleRemoveClick = (event) => {
      this.props.handleRemoveLink(this.props.index);
    };

  }

  render () {
    let values = this.state.values;
    let props = this.props;

    let fields = props.initialFields.map(function(array,index){

      let attribute = array[0];
      let value = values[index][attribute][props.index];
      let fieldProps = {};

      fieldProps.key = attribute + '_' + props.index;
      fieldProps.index = props.index;
      fieldProps.handleUpdate = props.handleUpdate;
      fieldProps.model = props.model;
      fieldProps.modelId = props.modelId;
      fieldProps.initialValue = value;
      fieldProps.handleUpdate = props.handleUpdate;
      fieldProps.attribute = attribute;
      fieldProps.disabled = props.disabled;
      fieldProps.name = array[1]['name'];
      fieldProps.placeholder = array[1]['placeholder'];
      fieldProps.description = array[1]['description'];
      fieldProps.min = array[1]['properties']['min'];
      fieldProps.max = array[1]['properties']['max'];
      fieldProps.step = array[1]['properties']['step'];
      fieldProps.pattern = array[1]['pattern'];
      fieldProps.required = array[1]['required'];

      switch (array[1]['input_type']) {
        case 'text_field':
          return <TextField {...fieldProps} />;
          break;
        case 'telephone_field':
          return <TelephoneField {...fieldProps} />;
          break;
        case 'url_field':
          return <UrlField {...fieldProps} />;
          break;
        case 'email_field':
          return <EmailField {...fieldProps} />;
          break;
        case 'number_field':
          return <NumberField {...fieldProps} />;
          break;
        case 'time_field':
          return <TimeField {...fieldProps} />;
          break;
        case 'currency_field':
          return <CurrencyField {...fieldProps} />;
          break;
        case 'date_field':
          return <DateField {...fieldProps} />;
          break;
        case 'datetime_local_field':
          return <DatetimeLocalField {...fieldProps} />;
          break;
        // no default
      }
    });


    return (
      <div>
        <div className="col-sm-1 col-sm-offset-11">
          <a
            className="edit-link btn btn-danger btn-xs"
            onClick={this.handleRemoveClick}>
            <i className="fa fa-minus"></i>
          </a>
        </div>
        {fields}
      </div>

    );
  }
}

MultipleWrapper.defaultProps = {
  values: []
};

MultipleWrapper.propTypes = {
  values: React.PropTypes.array
};

module.exports = MultipleFieldGroup;
