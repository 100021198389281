import { connect } from 'react-redux';
import { clearModalState, setSelectedUsersAndGroups } from '../actions/add_approvers_modal_actions';
import UserSelectorModal from './user_selector_modal';

const mapStateToProps = (state) => {
  const selectedUsers =  state.users.selected;
  const selectedGroups =  state.groups.selected;
  return {
    selectedUsers,
    selectedGroups
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleModalClose: () => {
      dispatch(clearModalState());
    },
    handleModalSubmit: (users = [], groups = [], url = '', step = '') => {
      const userIds = users.map((user) => user.id);
      const groupIds = groups.map((group) => group.id);

      dispatch(setSelectedUsersAndGroups({
        users: userIds,
        groups: groupIds,
        step,
        url
      }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectorModal);
