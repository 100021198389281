'use strict';

class MaxLength extends React.Component {
  constructor (props) {
    super(props);

    this.state = { maxlength: props.maxlength };

    this.handleChange = (event) => {
      this.setState({ maxlength: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group integer optional field_maxlength">
        <label
          className="integer optional col-sm-2 control-label"
          htmlFor="field_maxlength">
          Maximum Length
        </label>
        <div className="col-sm-10">
          <input
            className="numeric integer optional form-control"
            type="number"
            name="field[maxlength]"
            id="field_maxlength"
            value={this.state.maxlength}
            onChange={this.handleChange} />
          <p className="help-block">
            The Maximum Length of the field
          </p>
        </div>
      </div>
    );
  }
}

class Min extends React.Component {
  constructor (props) {
    super(props);

    this.state = { min: props.min };

    this.handleChange = (event) => {
      this.setState({ min: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group integer optional field_min">
        <label
          className="integer optional col-sm-2 control-label"
          htmlFor="field_min">
          Min Value
        </label>
        <div className="col-sm-10">
          <input
            className="numeric integer optional form-control"
            type="number"
            name="field[min]"
            id="field_min"
            value={this.state.min}
            onChange={this.handleChange} />
          <p className="help-block">
            The Minimum Value for this field.
          </p>
        </div>
      </div>
    );
  }
}

class Max extends React.Component {
  constructor (props) {
    super(props);

    this.state = { max: props.max };

    this.handleChange = (event) => {
      this.setState({ max: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group integer optional field_max">
        <label
          className="integer optional col-sm-2 control-label"
          htmlFor="field_max">
          Max Value
        </label>
        <div className="col-sm-10">
          <input
            className="numeric integer optional form-control"
            type="number"
            name="field[max]"
            id="field_max"
            value={this.state.max}
            onChange={this.handleChange} />
          <p className="help-block">
            The Maximum Value for this field.
          </p>
        </div>
      </div>
    );
  }
}

class Step extends React.Component {
  constructor (props) {
    super(props);

    this.state = { step: props.step };

    this.handleChange = (event) => {
      this.setState({ step: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group integer optional field_step">
        <label
          className="integer optional col-sm-2 control-label"
          htmlFor="field_step">
          Step
        </label>
        <div className="col-sm-10">
          <input
            className="numeric integer optional form-control"
            type="number"
            name="field[step]"
            id="field_step"
            value={this.state.step}
            onChange={this.handleChange} />
          <p className="help-block">
            The incremental value of each step.
          </p>
        </div>
      </div>
    );
  }
}

class Cols extends React.Component {
  constructor (props) {
    super(props);

    this.state = { cols: props.cols };

    this.handleChange = (event) => {
      this.setState({ cols: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group integer optional field_cols">
        <label
          className="integer optional col-sm-2 control-label"
          htmlFor="field_cols">
          Columns
        </label>
        <div className="col-sm-10">
          <input
            className="numeric integer optional form-control"
            type="number"
            name="field[cols]"
            id="field_cols"
            value={this.state.cols}
            onChange={this.handleChange} />
          <p className="help-block">
            The Number of columns for this field.
          </p>
        </div>
      </div>
    );
  }
}

class Rows extends React.Component {
  constructor (props) {
    super(props);

    this.state = { rows: props.rows };

    this.handleChange = (event) => {
      this.setState({ rows: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group integer optional field_rows">
        <label
          className="integer optional col-sm-2 control-label"
          htmlFor="field_rows">
          Rows
        </label>
        <div className="col-sm-10">
          <input
            className="numeric integer optional form-control"
            type="number"
            name="field[row]"
            id="field_row"
            value={this.state.rows}
            onChange={this.handleChange} />
          <p className="help-block">
            The Minimum Value for this field.
          </p>
        </div>
      </div>
    );
  }
}

class Placeholder extends React.Component {
  constructor (props) {
    super(props);

    this.state = { placeholder: props.placeholder };

    this.handleChange = (event) => {
      this.setState({ placeholder: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group string optional field_placeholder">
        <label
          className="string optional col-sm-2 control-label"
          htmlFor="field_placeholder">
          Placeholder
        </label>
        <div className="col-sm-10">
          <input
            className="string optional form-control"
            type="text"
            name="field[placeholder]"
            id="field_placeholder"
            value={this.state.placeholder}
            onChange={this.handleChange} />
          <p className="help-block">
            Placeholder for this field
          </p>
        </div>
      </div>
    );
  }
}


class Pattern extends React.Component {
  constructor (props) {
    super(props);

    this.state = { pattern: props.pattern };

    this.handleChange = (event) => {
      this.setState({ pattern: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group optional field_pattern">
        <label
          className="optional col-sm-2 control-label"
          htmlFor="field_pattern">
          Pattern
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <span className="input-group-addon">/</span>
            <input
              className="string optional form-control"
              type="text"
              name="field[pattern]"
              id="field_pattern"
              value={this.state.pattern}
              onChange={this.handleChange} />
            <span className="input-group-addon">/</span>
          </div>
          <p className="help-block">
            Use a regular expression (REGEX) to restrict the pattern. <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions" target="_blank">Learn More</a>
          </p>
        </div>
      </div>
    );
  }
}


class Multiple extends React.Component {
  constructor (props) {
    super(props);

    this.state = { checked: props.multiple };

    this.handleClick = (event) => {
      this.setState({ checked: event.target.checked });
    };
  }

  render () {
    return (
      <div>
        <div className="form-group boolean optional field_multiple">
          <div className="col-sm-offset-2 col-sm-10">
            <div className="checkbox">
              <label
                className="boolean optional"
                htmlFor="field_multiple">
                <input
                  className="boolean optional"
                  type="checkbox"
                  name="field[multiple]"
                  id="field_multiple"
                  disabled={this.props.disabled}
                  checked={this.state.checked}
                  onClick={this.handleClick} />
              </label>
            </div>
            <p className="help-block">
              Accepts Multiple
            </p>
          </div>
        </div>
        <RootSelect {...this.props} />
      </div>
    );
  }
}

class RootSelect extends React.Component {

  constructor (props) {
    super(props);

    this.props.roots.unshift({id:'', name: 'Select a parent field to group multiples.'});

    this.state = {
      selectedRoot: this._isSelected(this.props.roots)
    };

    this.handleChange = (event) => {
      this.setState({selectedRoot: event.target.value});
    };
  }

  _isSelected (array) {
    const isSelected = array.find(element =>
      element['id'] && element['id'] == this.props.parent_id);
    return isSelected ? isSelected['id'] : array[0]['id'];
  }

  render () {
    const createOptions = function(obj, index) {
      return <option key={index} value={obj['id']}>{obj['name']}</option>;
    };

    return (
      <div className="form-group select optional field_parent_id">
        <label
          className="select optional col-sm-2 control-label"
          htmlFor="entry_parent_id">
          Parent of Multiples
        </label>
        <div className="col-sm-10">
          <select
            className="select optional form-control"
            disabled={this.props.disabled}
            value={this.state.selectedRoot}
            onChange={this.handleChange}
            name="field[parent_id]"
            id="field[parent_id]">
            {this.props.roots.map(createOptions)}
          </select>
          <p className="help-block">
            If you would like to group together fields that accept a multiple
            select the parent field.
          </p>
        </div>
      </div>
    );
  }
}

class SelectMultiple extends React.Component {
  constructor (props) {
    super(props);

    this.state = { checked: props.multiple };

    this.handleClick = (event) => {
      this.setState({ checked: event.target.checked });
    };
  }

  render () {
    return (
      <div>
        <div className="form-group boolean optional field_multiple">
          <div className="col-sm-offset-2 col-sm-10">
            <div className="checkbox">
              <label
                className="boolean optional"
                htmlFor="field_multiple">
                <input
                  className="boolean optional"
                  type="checkbox"
                  name="field[multiple]"
                  id="field_multiple"
                  disabled={this.props.disabled}
                  checked={this.state.checked}
                  onClick={this.handleClick} />
              </label>
            </div>
            <p className="help-block">
              Accepts Multiple
            </p>
          </div>
        </div>
      </div>
    );
  }
}



class IncludeBlank extends React.Component {
  constructor (props) {
    super(props);

    this.state = { include_blank: props.include_blank == true ? 1 : 0 };

    this.handleChange = (event) => {
      this.setState({ include_blank: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group boolean optional field_include_blank">
        <div className="col-sm-offset-2 col-sm-12">
          <div className="checkbox">
            <input value="0" type="hidden" name="field[include_blank]" />
            <label
              className="boolean optional"
              htmlFor="field_include_blank">
              Include Blank
              <input
                className="boolean optional"
                type="checkbox"
                name="field[include_blank]"
                id="field_include_blank"
                value={this.state.include_blank}
                checked={this.props.include_blank}
                onChange={this.handleChange} />
            </label>
            <p className="help-block">
              Include Bank response in the selection.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

class CurrencyMin extends React.Component {
  constructor (props) {
    super(props);

    this.state = { min: props.min };

    this.handleChange = (event) => {
      this.setState({ min: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group optional field_min">
        <label
          className="optional col-sm-2 control-label"
          htmlFor="field_min">
          Min Value
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <span className="input-group-addon">$</span>
            <input
              className="numeric currency optional form-control"
              type="number"
              step="0.01"
              name="field[min]"
              id="field_min"
              value={this.state.min}
              onChange={this.handleChange} />
          </div>
          <p className="help-block">
            The Minimum Value for this field.
          </p>
        </div>
      </div>
    );
  }
}

class CurrencyMax extends React.Component {
  constructor (props) {
    super(props);

    this.state = { max: props.max };

    this.handleChange = (event) => {
      this.setState({ max: event.target.value });
    };
  }

  componentDidMount () {

    $(document).ready(function() {
      $('input.currency').currencyInput();
    });

  }


  render () {
    return (
      <div className="form-group optional field_max">
        <label
          className="optional col-sm-2 control-label"
          htmlFor="field_max">
          Max Value
        </label>
        <div className="col-sm-10">
          <div className="input-group">
            <span className="input-group-addon">$</span>
            <input
              className="numeric currency optional form-control"
              type="number"
              step="0.01"
              name="field[max]"
              id="field_max"
              value={this.state.max}
              onChange={this.handleChange} />
          </div>
          <p className="help-block">
            The Maximum Value for this field.
          </p>
        </div>
      </div>
    );
  }
}


class DateMin extends React.Component {
  constructor (props) {
    super(props);

    this.state = { min: props.min };

    this.handleChange = (event) => {
      this.setState({ min: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group date optional field_min">
        <label
          className="date optional col-sm-2 control-label"
          htmlFor="field_min">
          Minimum Date
        </label>
        <div className="col-sm-10">
          <input
            className="date optional form-control"
            type="date"
            name="field[min]"
            id="field_min"
            html5="true"
            value={this.state.min}
            onChange={this.handleChange} />
          <p className="help-block">
            Set the minimum date for this fields' range.
          </p>
        </div>
      </div>
    );
  }
}

class DateMax extends React.Component {
  constructor (props) {
    super(props);

    this.state = { max: props.max };

    this.handleChange = (event) => {
      this.setState({ max: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group date optional field_max">
        <label
          className="date optional col-sm-2 control-label"
          htmlFor="field_max">
          Maximum Date
        </label>
        <div className="col-sm-10">
          <input
            className="date optional form-control"
            type="date"
            name="field[max]"
            id="field_max"
            html5="true"
            value={this.state.max}
            onChange={this.handleChange} />
          <p className="help-block">
            Set the maximum date for this fields' range.
          </p>
        </div>
      </div>
    );
  }
}

class DatetimeLocalMin extends React.Component {
  constructor (props) {
    super(props);

    this.state = { min: props.min };

    this.handleChange = (event) => {
      this.setState({ min: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group datetime-local optional field_min">
        <label
          className="datetime-local optional col-sm-2 control-label"
          htmlFor="field_min">
          Minimum Datetime
        </label>
        <div className="col-sm-10">
          <input
            className="datetime-local optional form-control"
            type="datetime-local"
            name="field[min]"
            id="field_min"
            html5="true"
            value={this.state.min}
            onChange={this.handleChange} />
          <p className="help-block">
            Set the minimum datetime for this fields' range.
          </p>
        </div>
      </div>
    );
  }
}

class DatetimeLocalMax extends React.Component {
  constructor (props) {
    super(props);

    this.state = { max: props.max };

    this.handleChange = (event) => {
      this.setState({ max: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group datetime-local optional field_max">
        <label
          className="datetime-local optional col-sm-2 control-label"
          htmlFor="field_max">
          Maximum Datetime
        </label>
        <div className="col-sm-10">
          <input
            className="datetime-local optional form-control"
            type="datetime-local"
            name="field[max]"
            id="field_max"
            html5="true"
            value={this.state.max}
            onChange={this.handleChange} />
          <p className="help-block">
            Set the maximum datetime for this fields' range.
          </p>
        </div>
      </div>
    );
  }
}

class TimeMin extends React.Component {
  constructor (props) {
    super(props);

    this.state = { min: props.min };

    this.handleChange = (event) => {
      this.setState({ min: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group time optional field_min">
        <label
          className="time optional col-sm-2 control-label"
          htmlFor="field_min">
          Minimum Time
        </label>
        <div className="col-sm-10">
          <input
            className="time optional form-control"
            type="time"
            name="field[min]"
            id="field_min"
            html5="true"
            value={this.state.min}
            onChange={this.handleChange} />
          <p className="help-block">
            Set the minimum time for this fields' range.
          </p>
        </div>
      </div>
    );
  }
}

class TimeMax extends React.Component {
  constructor (props) {
    super(props);

    this.state = { max: props.max };

    this.handleChange = (event) => {
      this.setState({ max: event.target.value });
    };
  }

  render () {
    return (
      <div className="form-group time optional field_max">
        <label
          className="time optional col-sm-2 control-label"
          htmlFor="field_max">
          Maximum Time
        </label>
        <div className="col-sm-10">
          <input
            className="time optional form-control"
            type="time"
            name="field[max]"
            id="field_max"
            html5="true"
            value={this.state.max}
            onChange={this.handleChange} />
          <p className="help-block">
            Set the maximum time for this fields' range.
          </p>
        </div>
      </div>
    );
  }
}


class FieldProperties extends React.Component {
  render () {
    const {selected_input_type, ...props} = this.props; 
    switch (selected_input_type) {
      case 'check_box':
        return <div></div>;
        break;
      case 'color_field':
        return <div></div>;
        break;
      case 'date_field':
        return (
          <div>
            <DateMin {...props} />
            <DateMax {...props} />
            <Multiple {...props} />
          </div>
        );
        break;
      case 'datetime_local_field':
        return (
          <div>
            <DatetimeLocalMin {...props} />
            <DatetimeLocalMax {...props} />
            <Multiple {...props} />
          </div>
        );
        break;
      case 'url_field':
        return (
          <div>
            <Placeholder {...props} />
            <Multiple {...props} />
          </div>
        );
        break;
      case 'email_field':
        return (
          <div>
            <Placeholder {...props} />
            <Multiple {...props} />
          </div>
        );
        break;
      case 'number_field':
        return (
          <div>
            <Min {...props} />
            <Max {...props} />
            <Step {...props} />
            <Placeholder {...props} />
            <Multiple {...props} />
          </div>
        );
        break;
      case 'password_field':
        return (
          <div>
            <MaxLength {...props} />
            <Placeholder {...props} />
          </div>
        );
        break;
      case 'radio_button':
        return <RadioButton {...props} initialOptions={this.props.options} />; 
        break;
      case 'range_field':
        return (
          <div>
            <Min {...props} />
            <Max {...props} />
            <Step {...props} />
          </div>
        );
        break;
      case 'search_field':
        return (
          <div>
            <MaxLength {...props} />
            <Placeholder {...props} />
          </div>
        );
        break;
      case 'select':
        return (
          <div>
            <Select {...props} initialOptions={this.props.options} />
            <SelectMultiple {...props} />
          </div>
        );
        break;
      case 'telephone_field':
        return (
          <div>
            <Placeholder {...props} />
            <Multiple {...props} />
          </div>
        );
        break;
      case 'text_area':
        return (
          <div>
            <Cols {...props} />
            <Rows {...props} />
            <Placeholder {...props} />
          </div>
        );
        break;
      case 'text_field':
        return (
          <div>
            <Placeholder {...props} />
            <Pattern {...props} />
            <Multiple {...props} />
          </div>
        );
        break;
      case 'time_field':
        return (
          <div>
            <TimeMin {...props} />
            <TimeMax {...props} />
            <Multiple {...props} />
          </div>
        );
        break;
      case 'file_upload':
        return <div></div>;
        break;
      case 'currency_field':
        return (
          <div>
            <CurrencyMin {...props} />
            <CurrencyMax {...props} />
            <Multiple {...props} />
          </div>
        );
        break;
      case 'approver_select':
        return (
          <div>
            <ApproverSelect {...props} />
          </div>
        );
        break;
        // no default
    }
  }
}

module.exports = FieldProperties;
