'use strict';

class DateFieldContainer extends React.Component {
  constructor (props) {
    super(props);

    if (this.props.initialValues == null) {
      this.state = { values: [''] };
    } else {
      this.handleAddLink = this.handleAddLink.bind(this);
    }
    this.removeItem = this.removeItem.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  removeItem (index) {
    if (this.props.disabled === true) { return; }
    const values = this.state.values;
    values.splice(index, 1);
    this.setState({values: values});
  }

  handleAddLink () {
    if (this.props.disabled === true) { return; }
    const values = this.state.values;
    values.push('');
    this.setState({values: values});
  }

  updateValue (attribute,index,value) {
    const values = this.state.values;
    values[index] = value;
    this.setState({values: values});
  }

  render () {
    const dateFields = this.state.values.map(function(value, index) {
      return (
        <DateWrapper
          key={index}
          index={index}
          initialValue={value}
          handleRemoveLink={this.removeItem}
          handleUpdate={this.updateValue}
          name={this.props.name}
          model={this.props.model}
          modelId={this.props.modelId}
          attribute={this.props.attribute}
          min={this.props.min}
          max={this.props.max}
          description={this.props.description}
          disabled={this.props.disabled}
          required={this.props.required}
        />
      );
    }.bind(this));

    return (
      <div>
        {dateFields}
        <div className="col-sm-1 col-sm-offset-11">
          <span
            onClick={this.handleAddLink}
            className="edit-link btn btn-primary btn-xs">
            <i className="fa fa-plus"></i>
          </span>
        </div>
      </div>
    );
  }
}

DateFieldContainer.defaultProps = {
  model: 'entry',
  modelId: 'entry',
  name: 'Name',
  attribute: null,
  initialValues: [''],
  min: null,
  max: null,
  pattern: null,
  disabled: false,
  required: false
};

DateFieldContainer.propTypes = {
  model: React.PropTypes.string,
  modelId: React.PropTypes.string,
  name: React.PropTypes.string,
  attribute: React.PropTypes.string,
  initialValues: React.PropTypes.array,
  min: React.PropTypes.string,
  max: React.PropTypes.string,
  description: React.PropTypes.string,
  disabled: React.PropTypes.bool,
  required: React.PropTypes.bool
};

class DateWrapper extends React.Component {
  constructor (props) {
    super(props);

    this.state = { value: this.props.initialValue };

    this.handleRemoveClick = (event) => {
      this.props.handleRemoveLink(this.props.index);
    };
  }

  render () {
    return (
      <div>
        <div className="col-sm-1 col-sm-offset-11">
          <a
            className="edit-link btn btn-danger btn-xs"
            onClick={this.handleRemoveClick}>
            <i className="fa fa-minus"></i>
          </a>
        </div>
        <DateField {...this.props} />
      </div>
    );
  }
}

module.exports = DateFieldContainer;
