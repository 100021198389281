import { PropTypes } from 'react';
import TextField from 'material-ui/TextField';
import SearchIcon from 'material-ui/svg-icons/action/search';
import { grey300 } from 'material-ui/styles/colors';

const FilterInput = ({ filterTerm, onChange, searchText }) => (
  <div className="FilterInput">
    <div className="row">
      <div className="col-sm-12">
        <TextField
          fullWidth={true}
          value={filterTerm}
          hintText={<span>
            <SearchIcon
              color={grey300}
              style={{
                height: '15px',
                width: '15px',
                position: 'relative',
                top: '2px',
                left: '-2px'
              }}
            />
          {searchText}
          </span>}
          onChange={onChange}
        />
      </div>
    </div>
  </div>
);

FilterInput.propTypes = {
  searchText: PropTypes.string.isRequired,
  filterTerm: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default FilterInput;
