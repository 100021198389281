import { connect } from 'react-redux';
import RoleButton from './role_button';
import { setUserRole } from '../../actions/user_role_actions';

const mapDispatchToProps = (dispatch) => {
  return {
    onPress: (id, role) => {
      dispatch(setUserRole(id, role));
    }
  };
};

const mapStateToProps = () => ({ });

const UserRoleButton = connect(mapStateToProps, mapDispatchToProps)(RoleButton);
export default UserRoleButton;
