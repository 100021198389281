import { PropTypes } from 'react';
import FilterInput from './filter_input';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import { indexOf, find } from 'lodash';

const GroupsTable = ({ groups, selected, rowOnSelect, filter, filterOnChange }) => (
  <div className="GroupTable">
    <Table
      onRowSelection={
        (selectedIndexes) => {
          if(selectedIndexes === 'all'){ return selectedIndexes; }

          const ret = groups.map((g, i) => {
            const { id } = g;
            return { id, selected:(indexOf(selectedIndexes, i) !== -1)};
          });

          rowOnSelect(ret);
        }}
      multiSelectable={true}
      height={'500px'}
      fixedHeader={true}
      fixedFooter={true}
      selectable={true}
    >
      <TableHeader>
        <TableRow>
          <TableHeaderColumn colSpan={2}>
            <FilterInput
              filterTerm={filter}
              searchText="Search Groups"
              onChange={filterOnChange}
            />
          </TableHeaderColumn>
        </TableRow>
        <TableRow>
          <TableHeaderColumn>Name</TableHeaderColumn>
          <TableHeaderColumn>Description</TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody deselectOnClickaway={false}>
        {groups.map((group) => {
          const { id, name, description } = group;
          const isSelected = (typeof find(selected, { id }) !== 'undefined') ? true : false;
          return (
            <TableRow key={id} selected={isSelected}>
              <TableRowColumn>{name}</TableRowColumn>
              <TableRowColumn>{description}</TableRowColumn>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </div>
);




GroupsTable.propTypes = {
  filter: PropTypes.string,
  filterOnChange: PropTypes.func.isRequired,
  rowOnSelect: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape({
    id:  PropTypes.number.isRequired,
    name:  PropTypes.string,
    description:  PropTypes.string,
  })).isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id:  PropTypes.number.isRequired,
    name:  PropTypes.string,
    description:  PropTypes.string,
  })).isRequired
};

export default GroupsTable;
