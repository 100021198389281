// A Table for instances that are archived and unarchived

import { PropTypes } from 'react';
import Subheader from 'material-ui/Subheader';
import SearchIcon from 'material-ui/svg-icons/action/search';
import IconButton from 'material-ui/IconButton';
import TextField from 'material-ui/TextField';
import ArchivedIcon from 'material-ui/svg-icons/content/archive';
import LinkIcon from 'material-ui/svg-icons/content/link';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import Toggle from 'material-ui/Toggle';
import { grey300, blue500, lightBlack } from 'material-ui/styles/colors';

// Styles
//------------------------------------------------------------------------------
const headerStyles = {
  color: lightBlack,
  fontSize: '18px',
  overflow: 'hidden'
};

const approvedStyles = {
  overflow: 'hidden',
  width: '11.5rem',
  textAlign: 'center'
};

const defaultCellStyles = {
  overflow: 'hidden',
};

const miniIconStyles = {
  height: '35px',
  width: '35px',
  position: 'relative',
  top: '10px',
  left: '-5px'
};

const inlineSearchStyles = {
  float: 'right',
  textAlign: 'right',
  fontWeight: 400,
  marginRight: '1em'
};

const defaultTableStyles = {
  overflow: 'hidden'
};

// Component
//------------------------------------------------------------------------------
const InstancesTable = ({ instances, instanceInputOnChange, archiveToggleOnChange, hideTable }) => {
  if( hideTable ){ return null; }

  const fillableStyles = Object.assign({}, defaultTableStyles, {
    width: (Math.max(...instances.map( (inst) => inst.fillable_name.length))  - 2.5) + 'rem'
  });

  const formStyles = Object.assign({}, defaultTableStyles, {
    width: (Math.max(...instances.map( (inst) => inst.form_name.length)) - 2.5) + 'rem'
  });

  return (<div className="InstancesTable">
    <Table
      height="500px"
      fixedHeader={true}
      fixedFooter={true}
      showRowHover={true}
      selectable={false}
      showCheckboxes={false}
    >
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        enableSelectAll={false}
      >
        <TableRow>
          <TableHeaderColumn colSpan="5">
            <Subheader style={headerStyles}>
              <ArchivedIcon color={lightBlack} style={miniIconStyles} />
              All Instances
              <TextField
                onChange={instanceInputOnChange}
                style={inlineSearchStyles}
                hintText={<span>
                  <SearchIcon
                    color={grey300}
                    style={{
                      height: '15px',
                      width: '15px',
                      position: 'relative',
                      top: '2px',
                      left: '-2px'
                    }}
                  />
                  Find Instance
                </span>}
              />
              <Toggle
                onToggle={archiveToggleOnChange}
                style={{
                  float:'right',
                  width: '150px',
                  marginTop: '1em',
                  fontSize: '.75em',
                  marginRight: '2em'
                }}
                label="Show Archived"/>
            </Subheader>
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody
        displayRowCheckbox={false}
      >
        <TableRow>
          <TableRowColumn style={fillableStyles} >Fillable</TableRowColumn>
          <TableRowColumn style={formStyles} >Form</TableRowColumn>
          <TableRowColumn style={defaultTableStyles} >Current Step</TableRowColumn>
          <TableRowColumn style={approvedStyles} >% Approved</TableRowColumn>
          <TableRowColumn style={defaultTableStyles} >Updated</TableRowColumn>
          <TableRowColumn style={defaultTableStyles} >Token</TableRowColumn>
          <TableRowColumn style={defaultTableStyles} >Archived</TableRowColumn>
          <TableRowColumn style={{width: '100px'}}>
            {'  '}
          </TableRowColumn>
        </TableRow>
        { //Empty Table Row
          instances.length < 1 &&
          <TableRow>
            <TableRowColumn colSpan="8">
              <h2
                className="empty-message"
                style={{
                  fontSize: '3em',
                  fontWeight: 300,
                  padding: '.5em 0'
                }}>
                No Instances Available</h2>
            </TableRowColumn>
          </TableRow>
        }
        { instances.map( (instance) => {
          const { updated_at, current_step, instance_token, percentage, path, form_name, fillable_name, archived, archived_at } = instance;
          return (<TableRow key={instance_token}>
            <TableRowColumn sytle={fillableStyles}>{fillable_name}</TableRowColumn>
            <TableRowColumn sytle={defaultTableStyles}>{form_name}</TableRowColumn>
            <TableRowColumn sytle={defaultTableStyles}>{current_step}</TableRowColumn>
            <TableRowColumn sytle={approvedStyles}>{((percentage && percentage + ' %') || '0%')}</TableRowColumn>
            <TableRowColumn sytle={defaultTableStyles}>{updated_at}</TableRowColumn>
            <TableRowColumn sytle={defaultTableStyles}>{instance_token}</TableRowColumn>
            <TableRowColumn sytle={defaultTableStyles}>
              { archived &&
                <IconButton tooltip={`Archived At ${archived_at}`}>
                  <ArchivedIcon />
                </IconButton>
              }
            </TableRowColumn>
            <TableRowColumn style={{width: '100px' }}>
              <a href={path}>
                <IconButton
                  iconStyle={{ color: blue500 }}
                  style={{float: 'right'}}
                >
                  <LinkIcon />
                </IconButton>
              </a>
            </TableRowColumn>
          </TableRow>);
        })}
      </TableBody>
    </Table>
  </div>);
};

InstancesTable.propTypes = {
  instanceInputOnChange: PropTypes.func.isRequired,
  archiveToggleOnChange : PropTypes.func.isRequired,
  new_instance_path: PropTypes.string,
  currentStep: PropTypes.number,
  hideNewInstanceButton: PropTypes.bool,
  hideTable: PropTypes.bool,
  instances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      updated_at: PropTypes.string,
      revision: PropTypes.number,
      percentage: PropTypes.number,
      instance_token: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    })
  )
};

InstancesTable.defaultProps = {
  hideNewInstanceButton: false,
  hideTable: false,
};


export default InstancesTable;
