import { PropTypes } from 'react';
import { connect } from 'react-redux';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import FlatButton from 'material-ui/FlatButton';
import RecentIcon from 'material-ui/svg-icons/action/history';
import { blue700 } from 'material-ui/styles/colors';
import moment from 'moment';

const tableStyles = {
  token: {
    overflow: 'hidden',
    width: '20%'
  },
  approved: {
    overflow: 'hidden',
    width: '11.5rem',
    textAlign: 'center'
  },
  viewBtn: {
    width: '9rem',
    textAlign: 'center'
  },
  default: {
    overflow: 'hidden'
  }
};

const RecentActivityTab = ({ recent_instances }) => {
  const fillableStyles = Object.assign({}, tableStyles.default, {
    width: (Math.max(...recent_instances.map( (inst) => inst.fillable_name.length))  - 2.5) + 'rem'
  });

  const formStyles = Object.assign({}, tableStyles.default, {
    width: (Math.max(...recent_instances.map( (inst) => inst.form_name.length)) ) + 'rem'
  });

  const creatorStyles = Object.assign({}, tableStyles.default, {
    width: (Math.max(...recent_instances.map( (inst) => inst.creator.length))  + 2.25) + 'rem'
  });

  return (<section className="RecentActivityTab">
      <h3>
        <RecentIcon style={{
          height: 40,
          width: 40,
          margin: '0px 0.25em',
          position: 'relative',
          top: '.25em',
          fill: blue700
        }}/>
      Recent Activity
    </h3>
    <Table
      height="500px"
      fixedHeader={true}
      fixedFooter={true}
      showRowHover={true}
      selectable={false}
      showCheckboxes={false}
    >
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        enableSelectAll={false}
      >
        <TableRow>
          <TableHeaderColumn style={fillableStyles}>Fillable</TableHeaderColumn>
          <TableHeaderColumn style={formStyles}>Form</TableHeaderColumn>
          <TableHeaderColumn style={tableStyles.default}>Current Step</TableHeaderColumn>
          <TableHeaderColumn style={tableStyles.token}>Instance Token</TableHeaderColumn>
          <TableHeaderColumn style={creatorStyles}>Creator</TableHeaderColumn>
          <TableHeaderColumn style={tableStyles.default}>Last Update</TableHeaderColumn>
          <TableHeaderColumn style={tableStyles.approved}>% Approved</TableHeaderColumn>
          <TableHeaderColumn style={tableStyles.viewBtn}></TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody displayRowCheckbox={false}>
        {recent_instances.map((instance) =>{
          const { fillable_name, form_name, instance_token, creator, percentage, path, current_step, updated_at } = instance;
          return (
            <TableRow key={instance_token}>
              <TableRowColumn style={fillableStyles}>{fillable_name}</TableRowColumn>
              <TableRowColumn style={formStyles}>{form_name}</TableRowColumn>
              <TableRowColumn style={tableStyles.default}>{current_step}</TableRowColumn>
              <TableRowColumn style={tableStyles.token}>
                <a href={path}>{instance_token}</a>
              </TableRowColumn>
              <TableRowColumn style={creatorStyles}>{creator}</TableRowColumn>
              <TableRowColumn style={tableStyles.default}>
                {moment(updated_at, 'YYYY-MM-DDTHH:mm').format('MM/DD/YYYY h:mm a')}
              </TableRowColumn>
              <TableRowColumn style={tableStyles.approved}>{percentage} %</TableRowColumn>
              <TableRowColumn style={tableStyles.viewBtn}>
                <a href={path}>
                  <FlatButton
                    label="View"
                    secondary={true}
                    style={{float: 'right', margin: '.5em auto'}}
                  />
                </a>
              </TableRowColumn>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </section>
  );
};

RecentActivityTab.propTypes = {
  recent_instances: PropTypes.arrayOf(
    PropTypes.shape({
      fillable_name: PropTypes.string.isRequired,
      form_name: PropTypes.string.isRequired,
      instance_token: PropTypes.string.isRequired,
      creator: PropTypes.string,
      percentage: PropTypes.number,
      path: PropTypes.string.isRequired
    })
  )
};

// Redux functions
//------------------------------------------------------------------------------
const mapStateToProps = ({ recent_instances }) => ({ recent_instances } );
export default connect(mapStateToProps)(RecentActivityTab);
