import { PropTypes } from 'react';
// Material UI
import Avatar from 'material-ui/Avatar';
import Divider from 'material-ui/Divider';
import {List, ListItem} from 'material-ui/List';
import {Card, CardActions, CardHeader, CardText} from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
// Icons
import UserIcon from 'material-ui/svg-icons/social/person-outline';
import MailIcon from 'material-ui/svg-icons/communication/mail-outline';
import HomeIcon from 'material-ui/svg-icons/action/home';
import ContactPhoneIcon from 'material-ui/svg-icons/hardware/phone-iphone';
import WorkPhoneIcon from 'material-ui/svg-icons/action/work';
import HomePhoneIcon from 'material-ui/svg-icons/communication/phone';
import MobilePhoneIcon from 'material-ui/svg-icons/hardware/smartphone';
import FaxPhoneIcon from 'material-ui/svg-icons/action/print';
// Colors
import { blue200, blue400, blue700 } from 'material-ui/styles/colors';

const UserInfo = ({ first_name, last_name, picture, email, phones, residential_address, mailing_address, edit_path, initially_expanded}) => {
  const { fax, home, mobile, work } = phones;
  const avatar = (picture) ? <Avatar src={picture} /> :
                 (first_name.length > 0 && last_name.length > 0)? <Avatar>{first_name.slice(0,1)}{last_name.slice(0,1)}</Avatar> :
                 <Avatar icon={<UserIcon />}/>;

  return (
    <Card initiallyExpanded={initially_expanded}>
      <CardHeader
      title={`${first_name} ${last_name}`}
      subtitle={email}
      avatar={avatar}
      actAsExpander={true}
      showExpandableButton={true}
    />
    <CardText expandable={true}>
      <List>
        <ListItem
        key={1}
        primaryText="Phones"
        leftAvatar={<Avatar backgroundColor={blue200} icon={<ContactPhoneIcon />} />}
        initiallyOpen={true}
        primaryTogglesNestedList={true}
        nestedItems={[
          <Divider key={200}/>,
          <List key={1}>
            {(home && home.length > 1 && <ListItem
              disabled={true}
              key={1}
              primaryText={<span><b>Home:</b> {home} </span>}
              leftIcon={<HomePhoneIcon />} />)}
            {(mobile && mobile.length > 1 && <ListItem
              disabled={true}
              key={2}
              primaryText={<span><b>Mobile:</b> {mobile} </span>}
              leftIcon={<MobilePhoneIcon />} />)}
            {(work && work.length > 1 && <ListItem
              disabled={true}
              key={3}
              primaryText={<span><b>Work:</b> {work} </span>}
              leftIcon={<WorkPhoneIcon />} />)}
            {(fax && fax.length > 1 && <ListItem
              disabled={true}
              key={4}
              primaryText={<span><b>Fax:</b> {fax} </span>}
              leftIcon={<FaxPhoneIcon />} />)}
          </List>
        ]} />

      {(residential_address && residential_address.line1 && <ListItem
        key={2}
        primaryText="Residential Address"
        leftAvatar={<Avatar backgroundColor={blue400} icon={<HomeIcon />} />}
        initiallyOpen={true}
        primaryTogglesNestedList={true}
        nestedItems={[
          <Divider key={2}/>,
          <List key={1}>
            <ListItem insetChildren={true} disabled={true}>
              <div className="row">
                <div className="col-sm-12">{residential_address.line1}</div>
              </div>
              <div className="row">
                <div className="col-sm-12">{residential_address.line2}</div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {`${residential_address.city}, ${residential_address.state} ${residential_address.zip}`}
                </div>
              </div>
            </ListItem>
          </List>
        ]} />)}
      {(mailing_address && mailing_address.line1 && <ListItem
        primaryText="Mailing Address"
        leftAvatar={<Avatar backgroundColor={blue700} icon={<MailIcon />} />}
        initiallyOpen={true}
        primaryTogglesNestedList={true}
        nestedItems={[
          <List key={1}>
            <Divider key={2}/>
            <ListItem insetChildren={true} key={1} disabled={true}>
              <div className="row">
                <div className="col-sm-12">{mailing_address.line1}</div>
              </div>
              <div className="row">
                <div className="col-sm-12">{mailing_address.line2}</div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {`${mailing_address.city}, ${mailing_address.state} ${mailing_address.zip}`}
                </div>
              </div>
            </ListItem>
          </List>
        ]} />)}
      </List>
    </CardText>
    <CardActions expandable={true} style={{textAlign: 'right'}}>
      <FlatButton href={edit_path} label="Edit" secondary={true} />
    </CardActions>
  </Card>
  );
};

UserInfo.propTypes = {
  first_name: PropTypes.string,
  last_name:  PropTypes.string,
  edit_path:  PropTypes.string.isRequired,
  picture:    PropTypes.string,
  email:      PropTypes.string,
  initially_expanded: PropTypes.bool,
  phones: PropTypes.shape({
    home: PropTypes.string,
    work: PropTypes.string,
    mobile: PropTypes.string,
    fax: PropTypes.string,
  }),
  residential_address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city:  PropTypes.string,
    state: PropTypes.string,
    zip:   PropTypes.string,
  }),
  mailing_address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city:  PropTypes.string,
    state: PropTypes.string,
    zip:   PropTypes.string,
  })
};

UserInfo.defaultProps = {
  first_name: '',
  last_name:  '',
  picture:    '',
  email:      '',
  initially_expanded: false
};

export default UserInfo;
