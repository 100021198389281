import { PropTypes } from 'react';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';
import { updateUserFilter } from '../../actions/user_role_actions';
import SearchIcon from 'material-ui/svg-icons/action/search';
import { grey300 } from 'material-ui/styles/colors';

// Styles
//------------------------------------------------------------------------------
const inlineSearchStyles = {
  float: 'right',
  textAlign: 'right',
  fontWeight: 400,
  marginRight: '1em'
};

// Component
//------------------------------------------------------------------------------
const TextFilter = ({ filterTerm, filterOnChange, }) => (
  <div className="FilterInput">
    <div className="row">
      <div className="col-sm-12">
        <TextField
          style={inlineSearchStyles}
          fullWidth={true}
          value={filterTerm}
          hintText={<span>
            <SearchIcon
              color={grey300}
              style={{
                height: '15px',
                width: '15px',
                position: 'relative',
                top: '2px',
                left: '-2px'
              }}
            />
          Find User
          </span>}
          onChange={filterOnChange}
        />
      </div>
    </div>
  </div>
);

TextFilter.propTypes = {
  filterTerm: PropTypes.string.isRequired,
  filterOnChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return { filterTerm: state.userFilter };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterOnChange: (e) => {
      const filterText = e.target.value;
      dispatch(updateUserFilter(filterText));
    }
  };
};

const FilterInput = connect(mapStateToProps, mapDispatchToProps)(TextFilter);

export default FilterInput;
