import { clearUserFilter, clearGroupFilter, clearUsersSelected, clearGroupsSelected } from './user_role_actions';
import { safeCredentials, checkStatus } from '../utils/utils';

export function clearModalState(){
  return (dispatch) => {
    dispatch(clearUserFilter());
    dispatch(clearGroupFilter());
    dispatch(clearUsersSelected());
    dispatch(clearGroupsSelected());
  };
}

export function setSelectedUsersAndGroups(selected){
  const { users, groups, step, url } = selected;

  return function(dispatch) {
    dispatch(clearModalState());
    fetch(url,
      safeCredentials({
        method: 'PATCH',
        body: JSON.stringify({
          users,
          groups,
          step_id: step
        })
      })
    ).then(checkStatus)
    .then(() =>{ window.location.reload(); })
    .catch((error) => {
      console.error('request failed', error);
    });
  };
}
