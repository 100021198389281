"use strict";

const {Table, Column, Cell} = FixedDataTable;

class PageDataTable extends React.Component {
  constructor(props) {
    super(props);

    this._dataList = new ObjectDataListStore([{}]);
    this.state = { filteredDataList: this._dataList };

    this._onFilterChange = this._onFilterChange.bind(this);
  }

  componentDidMount () {
    $.ajax({
      url: "/pages.json",
      dataType: 'json',
      success: function(pages) {
        this._dataList = new ObjectDataListStore(pages);
        this.setState({filteredDataList: this._dataList});
      }.bind(this)
    });
  }

  // Not part of react. Written
  _onFilterChange(e) {
    // If the value changes to empty, load original _dataList
    if (!e.target.value) {
      this.setState({
        filteredDataList: this._dataList,
      });
    }

    // Run the search on the name field
    var filterBy = e.target.value.toLowerCase();
    var size = this._dataList.getSize();
    var filteredIndexes = [];
    for (var index = 0; index < size; index++) {
      var {name} = this._dataList.getObjectAt(index);
      if (name.toLowerCase().indexOf(filterBy) !== -1) {
        filteredIndexes.push(index);
      }
    }

    this.setState({
      filteredDataList: new DataListWrapper(filteredIndexes, this._dataList),
    });
  }

  render() {
    var {filteredDataList} = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-sm-4 form-group">
            <input
              onChange={this._onFilterChange}
              placeholder="Filter by Name"
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Table
              rowHeight={50}
              headerHeight={50}
              rowsCount={filteredDataList.getSize()}
              width={this.props.containerWidth}
              height={800}
              {...this.props}>
              <Column
                cell={
                  <CheckedCell
                    data={filteredDataList}
                    field="published?"
                  />
                  }
                  fixed={true}
                  width={40}
                />
                <Column
                  header={<Cell>Name</Cell>}
                  cell={
                    <LinkCell
                      data={filteredDataList}
                      field="name"
                      urlField="url"
                    />
                    }
                    fixed={true}
                    width={200}
                  />
                  <Column
                    header={<Cell>Creator</Cell>}
                    cell={
                      <TextCell
                        data={filteredDataList}
                        field="creator_full_name"
                      />
                      }
                      width={200}
                    />
                    <Column
                      header={<Cell>Updated At</Cell>}
                      cell={
                        <DateCell
                          data={filteredDataList}
                          field="updated_at"
                        />
                        }
                        width={200}
                      />
                      <Column
                        header={<Cell>Parent Name</Cell>}
                        cell={
                          <LinkCell
                            data={filteredDataList}
                            field="parent_name"
                            urlField="parent_url"
                          />
                          }
                          width={200}
                        />
                        <Column
                          header={<Cell>Page Depth</Cell>}
                          cell={
                            <TextCell
                              data={filteredDataList}
                              field="level_ordinal"
                            />
                            }
                            width={100}
                          />
                          <Column
                            header={<Cell>Feedbacks</Cell>}
                            cell={
                              <TextCell
                                data={filteredDataList}
                                field="feedbacks_count"
                              />
                              }
                              width={100}
                            />
                            <Column
                              header={<Cell>Actions</Cell>}
                              cell={
                                <ActionsCell
                                  data={filteredDataList}
                                  field="name"
                                  urlField="url"
                                />
                                }
                                width={120}
                              />
                            </Table>
                          </div>
                        </div>
                      </div>
    );
  }
}

module.exports = Dimensions()(PageDataTable)
