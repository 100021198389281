import { PropTypes } from 'react';
import Subheader from 'material-ui/Subheader';
import Description from 'material-ui/svg-icons/action/description';
import AddIcon from 'material-ui/svg-icons/content/add';
import SearchIcon from 'material-ui/svg-icons/action/search';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import { grey300, grey500, deepOrange200, deepOrange700, lightBlack } from 'material-ui/styles/colors';
import moment from 'moment';

// Styles
//------------------------------------------------------------------------------
const headerStyles = {
  color: lightBlack,
  fontSize: '18px'
};

const miniIconStyles = {
  height: '20px',
  width: '20px',
  position: 'relative',
  top: '5px',
  left: '-10px'
};

const inlineSearchStyles = {
  float: 'right',
  textAlign: 'right',
  fontWeight: 400,
  marginRight: '1em'
};

// Component
//------------------------------------------------------------------------------
const InstancesTable = ({ instances, instanceInputOnChange, new_instance_path, hideNewInstanceButton, hideTable, display_fields }) => {
  if( hideTable ){ return null; }

  return (<div className="InstancesTable">
    <Table
      height="500px"
      fixedHeader={true}
      fixedFooter={true}
      showRowHover={true}
      selectable={false}
      showCheckboxes={false}
    >
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        enableSelectAll={false}
      >
        <TableRow>
          <TableHeaderColumn colSpan="5">
            <Subheader style={headerStyles}>
              <Description color={deepOrange700} style={miniIconStyles} />
              Instances
              <FlatButton
                href={new_instance_path}
                rippleColor={deepOrange200}
                tooltip="Create New Instance"
                icon={<AddIcon  />}
                style={{float: 'right', color: deepOrange700, position: 'relative', top: '10px'}}
              />
              <TextField
                onChange={instanceInputOnChange}
                style={inlineSearchStyles}
                hintText={<span>
                  <SearchIcon
                    color={grey300}
                    style={{
                      height: '15px',
                      width: '15px',
                      position: 'relative',
                      top: '2px',
                      left: '-2px'
                    }}
                  />
                  Find Instance
                </span>}
              />
            </Subheader>
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody
        displayRowCheckbox={false}
      >
        <TableRow>
          {display_fields.map((field) => {
            return <TableRowColumn style={{ color: grey500, fontWeight: '500', overflow: 'hidden' }}>{field.name}</TableRowColumn>
          })}


          <TableRowColumn style={{ color: grey500, fontWeight: '500', width: "19rem" }}>Updated</TableRowColumn>
          <TableRowColumn style={{ color: grey500, fontWeight: '500', width: "12rem",  textAlign: "center" }}>% Approved</TableRowColumn>
          <TableRowColumn style={{width: '100px'}}>
            {"  "}
          </TableRowColumn>
        </TableRow>
        { instances.map( (instance) => {
          const { updated_at, revision, token, percentage, path, display_fields:custom_fields } = instance;
          return (<TableRow key={token}>

            {display_fields.map((field) => {
              return <TableRowColumn>{format_value(custom_fields[field.key])}</TableRowColumn>
            })}
            <TableRowColumn>{updated_at}</TableRowColumn>
            <TableRowColumn style={{width: "12rem", textAlign: "center"}}>{percentage + ' %'}</TableRowColumn>
            <TableRowColumn style={{width: '100px' }}>
              <a href={path}>
                <FlatButton
                  label="View"
                  primary={true}
                  style={{float: 'right'}}
                />
              </a>
            </TableRowColumn>
          </TableRow>);
        })}
      </TableBody>
    </Table>
  </div>);
};

InstancesTable.propTypes = {
  instanceInputOnChange: PropTypes.func.isRequired,
  new_instance_path: PropTypes.string,
  currentStep: PropTypes.number,
  hideNewInstanceButton: PropTypes.bool,
  hideTable: PropTypes.bool,
  instances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      updated_at: PropTypes.string,
      revision: PropTypes.number,
      percentage: PropTypes.number,
      token: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    })
  )
};

InstancesTable.defaultProps = {
  hideNewInstanceButton: false,
  hideTable: false,
};

const format_value = ({value, type}) => {
  switch(type) {
    case "time_field":
      return moment(value, "HH:mm").format("h:mm a");
    case "date_field":
      return moment(value, "YYYY-MM-DD").format("MMMM D, YYYY");
    case "datetime_local_field":
      return moment(value, "YYYY-MM-DDTHH:mm").format("MMMM D, YYYY h:mm a");
    case "email_field":
      return <a href={`mailto:${value}`}>{value}</a>;
    case "url_field":
      return <a href={value}>{value}</a>;
    case "file_upload":
      return <a href={value} target="_blank" className="btn btn-inverse btn-xs btn-default"><i className="fa fa-file-text-o"/>  Download</a>;
    case "check_box":
      return value ? "Yes" : "No";
    case "currency_field":
      return `$${value}`;
    default:
      return value;
  }
}

export default InstancesTable;
