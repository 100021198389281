import {Tabs, Tab} from 'material-ui/Tabs';
import VisibleUserTable from './user_selector_infinite/visible_user_table';
import VisibleGroupsTable from './user_selector_infinite/visible_group_table';
import AddUserIcon from 'material-ui/svg-icons/social/person-add';
import AddGroupIcon from 'material-ui/svg-icons/social/group-add';

const UserSelectorInfinite = () => {
  return (
    <section className="UserSelectorInfinite">
      <Tabs>
        <Tab
          icon={<AddUserIcon />}
          label="Select Users" >
          <VisibleUserTable />
        </Tab>
        <Tab
          icon={<AddGroupIcon />}
          label="Select Groups"
        >
          <VisibleGroupsTable />
        </Tab>
      </Tabs>
    </section>
  );
};

export default UserSelectorInfinite;
