import { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Step, Stepper, StepLabel } from 'material-ui/Stepper';

const StepperBar = ({ currentStep }) => (
  <div className="Stepper"><Stepper activeStep={currentStep}>
      <Step>
        <StepLabel>Select Fillable</StepLabel>
      </Step>
      <Step>
        <StepLabel>Select Form</StepLabel>
      </Step>
      <Step>
        <StepLabel>Select Instance</StepLabel>
      </Step>
    </Stepper></div>
);

StepperBar.propTypes = {currentStep: PropTypes.number.isRequired };

const mapStateToProps = (state) => {
  return { currentStep: state.data.currentStep };
};

export default connect(mapStateToProps)(StepperBar);
