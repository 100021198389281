/**-----------------------------------------------------------------------------
*  Notification component for user actions
*  see http://www.material-ui.com/#/components/snackbar
------------------------------------------------------------------------------*/
import { PropTypes } from 'react';
import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import CircularProgress from 'material-ui/CircularProgress';

import { yellow600, lightGreen500, red200 } from 'material-ui/styles/colors';

// Component
//------------------------------------------------------------------------------
const SnackBarComponent = ({open, message, type, timeout}) => (
  <Snackbar contentStyle={contentStyle(type)}
            open={open}
            message={message}
            autoHideDuration={timeout}
            action={action(type)}
  />
);

// Props
//------------------------------------------------------------------------------
SnackBarComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  timeout: PropTypes.integer,
};

// Helpers
//------------------------------------------------------------------------------
function contentStyle(type){
  switch(type){
    case 'pending':
      return { color: yellow600, fontWeight: 'normal' };
    case 'success':
      return { color: lightGreen500, fontWeight: 'bold' };
    case 'error':
      return { color: red200,   fontWeight: 'bold' };
    default:
      return { color: 'white', fontWeight: 'normal' };
  }
}

function action(type){
  switch(type){
    case 'pending':
      return <CircularProgress style={{ marginTop: '0.5rem' }}
                size={25} thickness={1} color="white" />;
    default:
      return null;
  }
}

// State
//------------------------------------------------------------------------------
const mapStateToProps = ({notifications:{ open=false, message='', type='default', timeout }}) => {
  return { open, message, type, timeout };
};

export default connect(mapStateToProps)(SnackBarComponent);
