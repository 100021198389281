import InstancesTable from './instances_table';
import { connect } from 'react-redux';
import AutoComplete from 'material-ui/AutoComplete';
import { setInstanceFilter } from '../../actions/fillables_filter_actions';

// Redux functions
//------------------------------------------------------------------------------
const mapStateToProps = (state) => {
  const { instances, new_instance_path, currentStep, display_fields } = state.data;
  return {
    new_instance_path,
    display_fields,
    currentStep,
    hideTable: ((typeof currentStep !== 'number' ) || currentStep < 2 ),
    instances: visibleInstances( state.filters.instances, instances)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    instanceInputOnChange: (e) => {
      dispatch(setInstanceFilter(e.target.value));
    },
  };
};

const visibleInstances = (filter, instances) => {
  return instances.filter( instance => AutoComplete.fuzzyFilter(filter, instance.token));
};

export default connect(mapStateToProps, mapDispatchToProps)(InstancesTable);
