import { PropTypes } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import GroupRoleButton from './group_role_button';

const GroupActionCell = ({ id, role }) =>(
  <div className="ActionCell">
    <ButtonGroup>
      <GroupRoleButton
        title="Own"
        id={id}
        role={role}
        type="primary"
        value="owner"
      />
      <GroupRoleButton
        title="Edit"
        id={id}
        role={role}
        type="success"
        value="editor"
      />
      <GroupRoleButton
        title="View"
        id={id}
        role={role}
        type="info"
        value="viewer"
      />
      <GroupRoleButton
        title="Hide"
        id={id}
        role={role}
        type="danger"
        value={null}
      />
    </ButtonGroup>
  </div>
);

GroupActionCell.propTypes = {
  id:  PropTypes.number.isRequired,
  role:  PropTypes.string.isRequired,
};

export default GroupActionCell;
