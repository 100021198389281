import UserInfo from './user_info';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { user } = state;
  user.initially_expanded = true;
  return user;
};

export default connect(mapStateToProps)(UserInfo);
