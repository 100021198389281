import { PropTypes } from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';

// Icons
import HideIcon from 'material-ui/svg-icons/action/visibility-off';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import OwnIcon from 'material-ui/svg-icons/action/verified-user';
import ReadIcon from 'material-ui/svg-icons/action/description';


// Styles
//------------------------------------------------------------------------------
const defaultCellStyles = {
  overflow: 'hidden',
};

const nameCellStyles = {
  width: '15em',
  overflow: 'hidden',
};

const permCellStyles = {
  width: '22.5em'
};

// Component
//------------------------------------------------------------------------------
const GroupsTable = ({ groups, roleOnChange }) => (
  <Table
    selectable={false}
    height="500px">
    <TableHeader
      displaySelectAll={false}
      adjustForCheckbox={false}
      >
      <TableRow>
        <TableHeaderColumn style={nameCellStyles}>Name</TableHeaderColumn>
        <TableHeaderColumn style={defaultCellStyles}>Description</TableHeaderColumn>
        <TableHeaderColumn style={permCellStyles}>Permissions</TableHeaderColumn>
      </TableRow>
    </TableHeader>
    <TableBody
      displayRowCheckbox={false}
      preScanRows={false} >
      {groups.map((group) => {
        const { id, name, description, role} = group;
        return (
          <TableRow key={`${id}-${name}`}>
            <TableRowColumn style={nameCellStyles}>{name}</TableRowColumn>
            <TableRowColumn style={defaultCellStyles}>{description}</TableRowColumn>
            <TableRowColumn style={permCellStyles}>
              <DropDownMenu
                underlineStyle={{ borderTop: 'none' }}
                autoWidth={false}
                value={(role || 'hide')}
                onChange={(e, key) => roleOnChange(id, key)}
                style={{
                  marginBottom: '.5em',
                  width: '95%'
                }}>
                <MenuItem
                  leftIcon={<OwnIcon />}
                  value={'owner'}
                  primaryText="Owner" />
                <MenuItem
                  leftIcon={<EditIcon />}
                  value={'editor'}
                  primaryText="Editor" />
                <MenuItem
                  leftIcon={<ReadIcon />}
                  value={'viewer'}
                  primaryText="Viewer" />
                <MenuItem
                  leftIcon={<HideIcon />}
                  value={'hide'}
                  primaryText="Hide" />
              </DropDownMenu>
            </TableRowColumn>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);


GroupsTable.propTypes = {
  roleOnChange: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id:  PropTypes.number.isRequired,
    name:  PropTypes.string,
    description:  PropTypes.string,
    role:  PropTypes.string,
  })).isRequired
};

export default GroupsTable;
