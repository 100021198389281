// A Material UI Text inbout with a debounced onChange event
// Passes target to onChange event
import { Component, PropTypes } from 'react';
import TextField from 'material-ui/TextField';
import { compose, debounce, property, throttle} from 'lodash';

class Searchbox extends Component {
  handleChange(e) {
    e.persist();

    const {target: {value=''}} = e;

    console.log('Searchbox: %s', e.target.value);
    this.setState({ value });
    this.props.onChange(value);

    compose(
      throttle(this.props.bind(this), 500, {leading: true}),
      property('target.value')
    );
  }

  render() {
    const { onChange, ...other } = this.props;
    const { value='' } = this.state;

    return (
      <TextField {...other} onChange={this.handleOnChange} value={value}  />
    );
  }

  static propTypes = {
    onChange: PropTypes.func
  }
}

export default Searchbox;
