import { SET_USERS_FILTER, SET_USER_ROLE_FILTER, SET_USER_ROLE,
        SET_GROUP_ROLE } from './action_types';

export function updateUserFilter(filter){
  return {type: SET_USERS_FILTER, filter };
}

export function updateGroupFilter(filter){
  return {type: 'SET_GROUP_FILTER', filter };
}

export function clearGroupFilter(){
  return {type: 'SET_GROUP_FILTER', filter: '' };
}

export function clearUserFilter(){
  return {type: SET_USERS_FILTER, filter: '' };
}

export function setUserRoleFilter(role){
  return {type: SET_USER_ROLE_FILTER, role };
}

export function setUsersSelected( indexes ){
  return {type: 'SET_USERS_SELECTED', indexes };
}

export function clearUsersSelected(){
  return {type: 'CLEAR_USERS_SELECTED' };
}

export function clearGroupsSelected(){
  return { type: 'CLEAR_GROUPS_SELECTED' };
}

export function setGroupsSelected( indexes ){
  return {type: 'SET_GROUPS_SELECTED', indexes };
}

export function setUserRole(id, role){
  return { type: SET_USER_ROLE, id, role };
}

export function setGroupRole(id, role){
  return { type: SET_GROUP_ROLE, id, role };
}
