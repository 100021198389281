export const SELECT_FILLABLE = 'SELECT_FILLABLE';
export const SET_FILLABLE_FILTER = 'SET_FILLABLE_FILTER';
export const SET_FORM_FILTER = 'SET_FORM_FILTER';
export const SET_INSTANCE_FILTER = 'SET_INSTANCE_FILTER';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_CURRENT_FILLABLE = 'SET_CURRENT_FILLABLE';
export const SET_CURRENT_FORM = 'SET_CURRENT_FORM';


export function selectFillable( id ){
  return {type: SELECT_FILLABLE, id };
}

export function clearSelectedFillable(){
  return {type: SELECT_FILLABLE, id: null };
}

export function setFillableFilter(filter){
  return {type: SET_FILLABLE_FILTER, filter };
}

export function setFormFilter(filter){
  return {type: SET_FORM_FILTER, filter };
}

export function setInstanceFilter(filter){
  return {type: SET_INSTANCE_FILTER, filter };
}

export function setCurrentStep(step){
  return {type: SET_CURRENT_STEP, step };
}

export function updateCurrentFillable( fillableId){
  return {type: SET_CURRENT_FILLABLE, fillableId };
}

export function updateCurrentForm( formId ){
  return {type: SET_CURRENT_FORM, formId };
}
