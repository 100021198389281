import { connect } from 'react-redux';
import UsersTab from './users_tab';
import { updateUserFilter, setUserRole } from '../../actions/user_role_actions';

import Fuse from 'fuse.js';

const fuseOptions = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.35,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  keys: ['first_name', 'last_name', 'email']
};

const getVisibleUsers = (users, filter) => {
  if(typeof filter === 'undefined' || filter === ''){
    return users;
  }

  const fuse = new Fuse(users, fuseOptions);
  const fuseFilteredUsers = fuse.search(filter);

  return fuseFilteredUsers;
};

const mapStateToProps = ({users, filter: {users:userFilter} }) => {
  const filteredUsers = getVisibleUsers(users, userFilter) || [];

  return {
    filterTerm: userFilter,
    users: filteredUsers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterOnChange: ({target: {value=''} }) => {
      dispatch(updateUserFilter(value));
    },

    roleOnChange: (id, key) => {
      const roles = ['owner', 'editor', 'viewer', null];
      dispatch(setUserRole(id, roles[key]));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTab);
