"use strict";

class FieldTypeSelector extends React.Component {
  constructor (props) {
    super(props);

    this.state = { selected_input_type: props.input_type};

    this.handleChange = (event) => {
      this.setState({selected_input_type: event.target.value});
    };
  }

  render () {

    return (
      <div>
        <div className="form-group select optional field_input_type">
          <label
            className="select optional col-sm-2 control-label"
            htmlFor="field_input_type">
            Input type
          </label>
          <div className="col-sm-10">
            <select
              className="select optional form-control"
              value={this.state.selected_input_type}
              onChange={this.handleChange}
              name="field[input_type]"
              disabled={this.props.disabled}
              id="field_input_type">
              {this.props.input_types.map(function(obj, index) {
                return <option key={index} value={obj.type}>{obj.name}</option>;
              })}
            </select>
            <p className="help-block">
              Pick the type of field you need.
            </p>
          </div>
        </div>
        <FieldProperties
          {...this.props}
          selected_input_type={this.state.selected_input_type} />
      </div>
    );
  }
}

FieldTypeSelector.defaultProps = {
  input_types: [],
  input_type: 'text_field',
  options: [],
  max: null,
  min: null,
  maxlength: null,
  rows: null,
  cols: null,
  step: null,
  disabled: false,
  multiple: false,
  include_blank: false,
  placeholder: null,
  pattern: null,
  value: null,
  parent_id: null,
  roots: [],
  groups: [],
  group_ids: []
};

FieldTypeSelector.propTypes = {
  input_types: React.PropTypes.array,
  input_type: React.PropTypes.string,
  options: React.PropTypes.array,
  max: React.PropTypes.string,
  min: React.PropTypes.string,
  maxlength: React.PropTypes.number,
  rows: React.PropTypes.number,
  cols: React.PropTypes.number,
  step: React.PropTypes.number,
  disabled: React.PropTypes.bool,
  multiple: React.PropTypes.bool,
  include_blank: React.PropTypes.bool,
  placeholder: React.PropTypes.string,
  pattern: React.PropTypes.string,
  value: React.PropTypes.string,
  parent_id: React.PropTypes.number,
  roots: React.PropTypes.array,
  groups: React.PropTypes.array,
  group_ids: React.PropTypes.array,
};

module.exports = FieldTypeSelector;
