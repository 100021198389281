import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';
import {List, ListItem, makeSelectable } from 'material-ui/List';
import FlatButton from 'material-ui/FlatButton';
import Avatar from 'material-ui/Avatar';
import AutoComplete from 'material-ui/AutoComplete';
import AddIcon from 'material-ui/svg-icons/content/add';
import AssignmentTurnedIn from 'material-ui/svg-icons/action/assignment-turned-in';
import IconButton from 'material-ui/IconButton';
import SearchIcon from 'material-ui/svg-icons/action/search';
import Check from 'material-ui/svg-icons/navigation/check';
import LinkIcon from 'material-ui/svg-icons/content/link';
import Subheader from 'material-ui/Subheader';
import { teal50, teal400, teal500, white, grey50, grey300, lightBlack } from 'material-ui/styles/colors';
import { updateCurrentForm, setFormFilter } from '../../actions/fillables_filter_actions';
import { wrapState } from '../../utils/utils';

// Styles
//------------------------------------------------------------------------------
const headerStyles = {
  color: lightBlack,
  fontSize: '18px',
  backgroundColor: grey50
};

const miniIconStyles = {
  height: '20px',
  width: '20px',
  position: 'relative',
  top: '5px',
  left: '-5px'
};

const inlineSearchStyles = {
  float: 'right',
  textAlign: 'right',
  fontWeight: 400,
  marginRight: '0.5em',
  width: '175px',
};

const selectedItemStyle = {
  backgroundColor: teal50,
  border: 'none'
};

// Component
//------------------------------------------------------------------------------
const SelectableList = wrapState( makeSelectable(List), selectedItemStyle);

const FormsList = ({ currentStep, forms, new_form_path, can_edit_fillable,
  formOnClick, formInputOnChange }) => {
  // Hide until Fillable is clicked
  if( (typeof currentStep !== 'number' ) || currentStep < 1 ){ return null; }

  return <section className="FormsList">
    <SelectableList>
      <Subheader
        className="ListHeader"
        style={headerStyles}>
        <Check color={teal400} style={miniIconStyles}/>
        Forms
        {can_edit_fillable &&
          <FlatButton
            href={new_form_path}
            tooltip="Create New Instance"
            icon={<AddIcon  />}
            style={{
              float: 'right',
              color: teal400,
              position: 'relative',
              top: '5px',
              minWidth: '50px',
              marginRight: '.5em',
            }}
          />
        }
        <TextField style={inlineSearchStyles}
          className="SearchField"
          onChange={formInputOnChange}
          hintText={
            <span>
              <SearchIcon
                color={grey300}
                style={{
                  height: '15px',
                  width: '15px',
                  position: 'relative',
                  top: '3px',
                  left: '-2px'
                }}
              />
            Search Forms
            </span>
          }
        />
      </Subheader>
      {forms.map((form) => {
        const { id, name, description, path } = form;
        return <ListItem
                  value={id}
                  key={id}
                  id={id}
                  primaryText={name}
                  secondaryText={description}
                  onTouchTap={() => formOnClick(id) }
                  secondaryTextLines={2}
                  leftAvatar={<Avatar
                      backgroundColor={teal400}
                       color={white}
                      icon={<AssignmentTurnedIn />}
                    />}
                    rightIconButton={
                      <a href={path}>
                        <IconButton
                          style={{
                            marginTop: '10px',
                            borderRadius: '50%',
                            width: '50px'
                          }}
                          iconStyle={{
                            height: '35px',
                            width: '35px'
                          }} >
                          <LinkIcon color={teal500}/>
                        </IconButton>
                      </a>}
                />;
      })}
    </SelectableList>
  </section>;
};

FormsList.propTypes = {
  selectedForm: PropTypes.number.isRequired,
  new_form_path: PropTypes.string.isRequired,
  can_edit_fillable: PropTypes.bool,
  currentStep: PropTypes.number,
  formOnClick: PropTypes.func.isRequired,
  formInputOnChange: PropTypes.func.isRequired,
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  )
};

// Redux functions
//------------------------------------------------------------------------------
const mapStateToProps = (state) => {
  const { selectedForm, new_form_path, can_edit_fillable, currentStep, forms } = state.data;
  return {
    selectedForm,
    new_form_path,
    can_edit_fillable,
    currentStep,
    forms: visibleForms(state.filters.forms, forms)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    formInputOnChange: (e) => {
      dispatch(setFormFilter(e.target.value));
    },
    formOnClick: (formId) => {
      dispatch(updateCurrentForm(formId));
      setTimeout(() => {
        const elem = document.querySelector('.InstancesTable');
        elem && $('html, body').animate({
          scrollTop: $(elem).offset().top
        }, 500);
      }, 200);
    }
  };
};

const visibleForms = (filter, forms) => {
  return forms.filter( form => AutoComplete.fuzzyFilter(filter, form.name));
};


export default connect(mapStateToProps,mapDispatchToProps)(FormsList);
