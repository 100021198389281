import InstancesTable from './entries_list';
import { connect } from 'react-redux';
import AutoComplete from 'material-ui/AutoComplete';
import { setEntriesFilter } from '../../actions/current_user_actions';

// Redux functions
//------------------------------------------------------------------------------
const mapStateToProps = (state) => {
  const { entries } = state;
  const { entries:entry_filter } = state.filters;

  return {
    entries: visibleEntries(entry_filter, entries)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    instanceInputOnChange: (e) => {
      dispatch( setEntriesFilter(e.target.value));
    },
  };
};

const visibleEntries = (filter, entries) => {
  return entries.filter( (entry) => {
    const { name } = entry.fillable;
    return  AutoComplete.fuzzyFilter(filter, name);
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(InstancesTable);
