import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { muiTheme } from '../utils/utils';
import VisibleUserTable from './user_role_selector/visible_user_table';
import VisibleGroupsTable from './user_role_selector/visible_group_table';
import Snackbar from './user_role_selector/snackbar';
import {Tabs, Tab} from 'material-ui/Tabs';
import UserIcon from 'material-ui/svg-icons/social/person';
import GroupIcon from 'material-ui/svg-icons/social/group';

const UserRoleSelector = () => (
  <MuiThemeProvider muiTheme={muiTheme}>
    <section className="UserRoleSelector">
      <Tabs>
        <Tab label="Users" icon={<UserIcon />}>
          <VisibleUserTable />
        </Tab>
        <Tab label="Groups" icon={<GroupIcon />}>
          <VisibleGroupsTable />
        </Tab>
      </Tabs>
      <Snackbar />
    </section>
  </MuiThemeProvider>
);

export default UserRoleSelector;
