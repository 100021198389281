import { PropTypes } from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import TextField from 'material-ui/TextField';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import Subheader from 'material-ui/Subheader';

// Icons
import UserPermIcon from 'material-ui/svg-icons/action/verified-user';
import SearchIcon from 'material-ui/svg-icons/action/search';
import HideIcon from 'material-ui/svg-icons/action/visibility-off';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import OwnIcon from 'material-ui/svg-icons/action/verified-user';
import ReadIcon from 'material-ui/svg-icons/action/description';

import { grey300, blue200, lightBlack } from 'material-ui/styles/colors';

// Styles
//------------------------------------------------------------------------------
const headerStyles = {
  color: lightBlack,
  fontSize: '18px'
};

const miniIconStyles = {
  height: '20px',
  width: '20px',
  position: 'relative',
  top: '5px',
  left: '-10px'
};

const inlineSearchStyles = {
  float: 'right',
  textAlign: 'right',
  fontWeight: 400,
  marginRight: '1em'
};

const searchIconStyles = {
  height: '15px',
  width: '15px',
  position: 'relative',
  top: '2px',
  left: '-2px'
};


// Component
//------------------------------------------------------------------------------
const UsersTab = ({ users, roleOnChange, filterTerm, filterOnChange }) => {
  return (<section className="UserTab">
    <div className="row">
      <div className="col-sm-7">
        <Subheader style={headerStyles}>
          <UserPermIcon color={blue200} style={miniIconStyles} />
          Set User Permissions
        </Subheader>
      </div>
      <div className="col-sm-5">
        <TextField
          style={inlineSearchStyles}
          fullWidth={true}
          value={filterTerm}
          hintText={<span>
            <SearchIcon color={grey300} style={searchIconStyles} />
            Find User </span>}
          onChange={filterOnChange}
        />
      </div>
    </div>

    <Table
      selectable={false}
      height="500px">
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        >
        <TableRow>
          <TableHeaderColumn>First Name</TableHeaderColumn>
          <TableHeaderColumn>Last Name</TableHeaderColumn>
          <TableHeaderColumn>Email</TableHeaderColumn>
          <TableHeaderColumn>Permissions</TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody
        displayRowCheckbox={false}
        preScanRows={false} >
        {users.map((user) => {
          const { id, first_name, last_name, email, role} = user;
          return (
            <TableRow key={id}>
              <TableRowColumn>{first_name}</TableRowColumn>
              <TableRowColumn>{last_name}</TableRowColumn>
              <TableRowColumn>{email}</TableRowColumn>
              <TableRowColumn>
                <DropDownMenu
                  underlineStyle={{ borderTop: 'none' }}
                  autoWidth={false}
                  value={(role || 'hide')}
                  onChange={(e, key) => roleOnChange(id, key)}
                  style={{
                    marginBottom: '.5em',
                    width: '95%'
                  }}>
                  <MenuItem
                    leftIcon={<OwnIcon />}
                    value={'owner'}
                    primaryText="Owner" />
                  <MenuItem
                    leftIcon={<EditIcon />}
                    value={'editor'}
                    primaryText="Editor" />
                  <MenuItem
                    leftIcon={<ReadIcon />}
                    value={'viewer'}
                    primaryText="Viewer" />
                  <MenuItem
                    leftIcon={<HideIcon />}
                    value={'hide'}
                    primaryText="Hide" />
                </DropDownMenu>
              </TableRowColumn>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </section>
  );
};

// Props
//------------------------------------------------------------------------------
UsersTab.propTypes = {
  filterTerm: PropTypes.string.isRequired,
  filterOnChange: PropTypes.func.isRequired,
  roleOnChange: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    id:  PropTypes.number.isRequired,
    first_name:  PropTypes.string,
    last_name:  PropTypes.string,
    email:  PropTypes.string.isRequired,
    role:  PropTypes.string,
  })).isRequired
};

export default UsersTab;
