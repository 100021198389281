'use strict';

class ApproverSelect extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      selectedGroupIds: this.props.group_ids,
    };

    this.handleChange = (event) => {
      // We have to iterate the `options` elements
      // to figure out which ones are selected.
      let selectedValue = [];
      const options = event.target.options;
      for (let i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          selectedValue.push(parseInt(options[i].value));
        }
      }

      // let ids = this.state.selectedGroupIds;
      this.setState({selectedGroupIds: selectedValue});
    };
  }

  render () {
    let createOptions = function(obj, index) {
      return <option key={index} value={obj['id']}>{obj['description']}</option>;
    };

    return (
      <div>
        <div className="form-group select optional field_input_type">
          <label
            className="select optional col-sm-2 control-label"
            htmlFor="field_group_ids">
            Group
          </label>
          <div className="col-sm-10">
            <select
              className="select optional form-control"
              multiple={true}
              value={this.state.selectedGroupIds}
              onChange={this.handleChange}
              name="field[group_ids][]"
              id="field_group_ids">
              {this.props.groups.map(createOptions)}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

module.exports = ApproverSelect;
