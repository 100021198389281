window.Select = React.createClass({
  propTypes: {
    initialOptions: React.PropTypes.array
  },
  getDefaultProps: function(){
    return {
      initialOptions: ['']
    };
  },
  getInitialState: function(){
    return {
      options: this.props.initialOptions
    };
  },
  removeItem: function(index){
    var options = this.state.options;
    options.splice(index, 1);
    this.setState({options: options});
    return true;
  },
  handleAddLink: function(){
    var options = this.state.options;
    options.push('');
    this.setState({options: options});
  },
  render: function() {
    var options = this.state.options;
    return (
      <div>
        <h4>
          Options 
          <span
            onClick={this.handleAddLink}
            className="pull-right edit-link btn btn-success btn-sm">
            <i className="fa fa-plus"></i>
          </span>
        </h4>
        {options.map(function(option, index) {
          return <SelectOptionRow
            key={index}
            index={index}
            initialName={option}
            handleRemoveLink={this.removeItem}
          />;}.bind(this))}
        </div>
    );
  }
});

var SelectOptionRow = React.createClass({
  mixins: [LinkedStateMixin],
  propTypes: {
    initialName: React.PropTypes.string,
    index: React.PropTypes.number,
    handleRemoveLink: React.PropTypes.func
  },
  getInitialState: function(){
    return {
      name: this.props.initialName,
    };
  },
  handleRemoveClick: function(e){
    this.props.handleRemoveLink(this.props.index);
  },
  render: function() {
    return (
      <div className="form-group optional field_options">
        <label
          className="string required col-sm-2 control-label"
          htmlFor="field_options">
          Name:
        </label>
        <div className="col-sm-9">
          <input
            type='text'
            name="field[options][]"
            valueLink={this.linkState('name')}
            className='required form-control'
            placeholder='Name' />
          <p className="help-block">
            Add a name for this option.
          </p>
        </div>
        <div className="delete-cell col-sm-1">
          <a
            className="btn btn-danger btn-sm"
            onClick={this.handleRemoveClick}>
            X
          </a>
        </div>
      </div>
    );
  }
});

module.exports = Select;
