'use strict';

class UrlFieldContainer extends React.Component {
  constructor (props) {
    super(props);

    if (this.props.initialValues == null) {
      this.state = { values: [''] };
    } else {
      this.state = { values: this.props.initialValues };
    }
    this.handleAddLink = this.handleAddLink.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  removeItem (index) {
    if (this.props.disabled === true) { return; }
    const values = this.state.values;
    values.splice(index, 1);
    this.setState({values: values});
  }

  handleAddLink () {
    if (this.props.disabled === true) { return; }
    const values = this.state.values;
    values.push('');
    this.setState({values: values});
  }

  updateValue (attribute,index,value) {
    const values = this.state.values;
    values[index] = value;
    this.setState({values: values});
  }

  render () {
    const textFields = this.state.values.map(function(value, index) {
      return (
        <UrlWrapper
          key={index}
          index={index}
          initialValue={value}
          handleRemoveLink={this.removeItem}
          handleUpdate={this.updateValue}
          name={this.props.name}
          model={this.props.model}
          modelId={this.props.modelId}
          attribute={this.props.attribute}
          placeholder={this.props.placeholder}
          description={this.props.description}
          pattern={this.props.pattern}
          disabled={this.props.disabled}
          required={this.props.required}
        />
      );
    }.bind(this));

    return (
      <div>
        {textFields}
        <div className="col-sm-1 col-sm-offset-11">
          <span
            onClick={this.handleAddLink}
            className="edit-link btn btn-primary btn-xs">
            <i className="fa fa-plus"></i>
          </span>
        </div>
      </div>
    );
  }
}

UrlFieldContainer.defaultProps = {
  model: 'entry',
  modelId: 'entry',
  name: 'Name',
  attribute: null,
  initialValues: [''],
  placeholder: null,
  description: null,
  pattern: null,
  disabled: false,
  required: false
};

UrlFieldContainer.propTypes = {
  model: React.PropTypes.string,
  modelId: React.PropTypes.string,
  name: React.PropTypes.string,
  attribute: React.PropTypes.string,
  initialValues: React.PropTypes.array,
  placeholder: React.PropTypes.string,
  description: React.PropTypes.string,
  pattern: React.PropTypes.string,
  disabled: React.PropTypes.bool,
  required: React.PropTypes.bool
};

class UrlWrapper extends React.Component {
  constructor (props) {
    super(props);

    this.state = { value: this.props.initialValue };

    this.handleRemoveClick = (event) => {
      this.props.handleRemoveLink(this.props.index);
    };

  }

  render () {
    return (
      <div>
        <div className="col-sm-1 col-sm-offset-11">
          <a
            className="edit-link btn btn-danger btn-xs"
            onClick={this.handleRemoveClick}>
            <i className="fa fa-minus"></i>

          </a>
        </div>
        <UrlField {...this.props} />
      </div>
    );
  }
}

module.exports = UrlFieldContainer;

