"use strict";

const {Cell} = FixedDataTable;

class DataListWrapper {
  constructor(indexMap, data) {
    this._indexMap = indexMap;
    this._data = data;
  }

  getSize() {
    return this._indexMap.length;
  }

  getObjectAt(index) {
    return this._data.getObjectAt(
      this._indexMap[index],
    );
  }
}

class ObjectDataListStore {
  constructor(data) {
    this._cache = data;
  }

  getObjectAt(/*number*/ index) /*?object*/ {
    if (index < 0 || index > this._cache.length){
      return undefined;
    }
    return this._cache[index];
  }

  getSize() {
    return this._cache.length;
  }
}

class TextCell extends React.Component {
  render() {
    const {rowIndex, field, data, ...props} = this.props;
    return (
      <Cell {...props}>
        {data.getObjectAt(rowIndex)[field]}
      </Cell>
    );
  }
}

class LinkCell extends React.Component {
  render() {
    const {rowIndex, field, urlField, data, ...props} = this.props;
    const link = data.getObjectAt(rowIndex)[field];
    const linkUrl = data.getObjectAt(rowIndex)[urlField];
    return (
      <Cell {...props}>
        <a href={linkUrl}>{link}</a>
      </Cell>
    );
  }
}

class DateCell extends React.Component {
  render() {
    const {rowIndex, field, data, ...props} = this.props; 
    const date = new Date(data.getObjectAt(rowIndex)[field]);
    return (
      <Cell {...props}>
        {date.toLocaleString()}
      </Cell>
    );
  }
}

class CheckedCell extends React.Component {

  render() {
    const {rowIndex, field, data, ...props} = this.props; 
    const div = data.getObjectAt(rowIndex)[field] ? "label-success" : "label-danger";
    const i = data.getObjectAt(rowIndex)[field] ? "fa-check" : "fa-times";
    return (
      <Cell {...props}>
        <div className={"label " + div}>
          <i className={"fa " + i}></i>
        </div>
      </Cell>
    );
  }
}

class ActionsCell extends React.Component {
  render() {
    const {rowIndex, field, urlField, data, ...props} = this.props;
    const link = data.getObjectAt(rowIndex)[field];
    const linkUrl = data.getObjectAt(rowIndex)[urlField];
    return (
      <Cell {...props}>
        <div
          className="btn-group btn-group-sm"
          role="group">
          <a
            className="btn btn-default"
            href={linkUrl + "/edit"}>
            Edit
          </a>
          <a
            className="btn btn-danger"
            data-confirm={"This will permanently delete " + link}
            rel="nofollow"
            data-method="delete"
            href={linkUrl}>
            <i className="fa fa-trash"></i>
          </a>
        </div>
      </Cell>
    );
  }
}

module.exports = {
  TextCell: TextCell,
  LinkCell: LinkCell,
  DateCell: DateCell,
  CheckedCell: CheckedCell,
  ActionsCell: ActionsCell,
  DataListWrapper: DataListWrapper,
  ObjectDataListStore: ObjectDataListStore
}
