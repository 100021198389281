import StepperBar from './fillable_filter/stepper_bar';
import FillableList from './fillable_filter/fillable_list';
import FormsList from './fillable_filter/forms_list';
import VisibleInstancesTable from './fillable_filter/visible_instances_table';

const FillableFilter = () => (
  <section className="FillableFilter">
    <h3>Find Fillable Form Instances</h3>
    <StepperBar />
    <div className="row">
      <div className="FillableListCell col-sm-6 col-lg-6">
        <FillableList />
      </div>
      <div className="FormsListCell col-sm-6 col-lg-6">
        <FormsList />
      </div>
      <div className="InstancesTableCell col-sm-12 col-lg-12">
        <VisibleInstancesTable />
      </div>
    </div>
  </section>
);

export default FillableFilter;
