// Setup React in global scope
window.React = require('react');
window.ReactDOM = require('react-dom');
window.LinkedStateMixin = require('react-addons-linked-state-mixin');

window.Dimensions = require('react-dimensions');
const _ = require('lodash');

const RadioButton = require('../components/radio_button');
const Select = require('../components/select');
window.FieldTypeSelector = require('../components/field_type_selector');
window.FieldProperties = require('../components/field_properties');
window.CategoriesContainer = require('../components/categories_container');
window.TextFieldContainer = require('../components/text_field_container');
window.TelephoneFieldContainer = require('../components/telephone_field_container');
window.UrlFieldContainer = require('../components/url_field_container');
window.EmailFieldContainer = require('../components/email_field_container');
window.NumberFieldContainer = require('../components/number_field_container');
window.TimeFieldContainer = require('../components/time_field_container');
window.CurrencyFieldContainer = require('../components/currency_field_container');
window.DateFieldContainer = require('../components/date_field_container');
window.DatetimeLocalFieldContainer = require('../components/datetime_local_field_container');
window.UserRoleSelector = require('../components/user_role_selector');
window.MultipleFieldGroup = require('../components/multiple_field_group');
const _Inputs = require('../components/inputs');
window.Label = _Inputs.Label;
window.TextField = _Inputs.TextField;
window.TelephoneField = _Inputs.TelephoneField;
window.UrlField = _Inputs.UrlField;
window.EmailField = _Inputs.EmailField;
window.NumberField = _Inputs.NumberField;
window.TimeField = _Inputs.TimeField;
window.CurrencyField = _Inputs.CurrencyField;
window.DateField = _Inputs.DateField;
window.DatetimeLocalField = _Inputs.DatetimeLocalField;
window.UserSelectorModal = require('../components/user_selector_modal');
window.ApproverSelect = require('../components/approver_select');
