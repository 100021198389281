import { Component, PropTypes } from 'react';
import { grey700 } from 'material-ui/styles/colors';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import AddUserIcon from 'material-ui/svg-icons/social/person-add';
import UserSelectorInfinite from './user_selector_infinite';

// styles
//------------------------------------------------------------------------------

const btnStyles = {
  fontWeight: 400,
  fontSize: '12px',
  color: grey700,
};


// Component
//------------------------------------------------------------------------------

class UserSelectorModal extends Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
    this.props.handleModalClose();
  };

  handleSubmit = () => {
    const { selectedUsers, selectedGroups, url, step_id } = this.props;

    this.props.handleModalSubmit(
      selectedUsers,
      selectedGroups,
      url,
      step_id
    );
    this.setState({open: false});
  };

  render() {
    const actions = [
      <FlatButton
        label="Cancel"
        secondary={true}
        onTouchTap={this.handleClose}
      />,
      <FlatButton
        label="Add Selected"
        primary={true}
        onTouchTap={this.handleSubmit}
      />,
    ];

    return (
      <div>
        <FlatButton
          style={{
            display: 'inline-block',
            position: 'relative',
            top: '-0.75rem'
          }}
          labelStyle={btnStyles}
          label="Add Approvers"
          icon={<AddUserIcon color={grey700} />}
          onTouchTap={this.handleOpen} />
        <Dialog
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          <UserSelectorInfinite />
        </Dialog>
      </div>
    );
  }
}

UserSelectorModal.propTypes = {
  selectedUsers: PropTypes.array.isRequired,
  selectedGroups: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
  step_id: PropTypes.string.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
};

export default UserSelectorModal;
