'use strict';

class Label extends React.Component {

  render () {
    if (this.props.required === true) {
      return (
        <label
          className={'string col-sm-2 control-label ' + this.props.required + ' ' + this.props.disabled}
          htmlFor={this.props.fieldId + '_' + this.props.attribute}>
          <abbr title="required">*</abbr>
          {' ' + this.props.name}
        </label>
      );
    }

    return (
      <label
        className={'string col-sm-2 control-label ' + this.props.disabled}
        htmlFor={this.props.fieldId + '_' + this.props.attribute}>
        {this.props.name}
      </label>
    );
  }
}


class TextField extends React.Component {
  constructor (props) {
    super(props);

    this.state = { value: this.props.initialValue };

    this.handleChange = (event) => {
      this.props.handleUpdate(this.props.attribute, this.props.index, event.target.value);
      this.setState({ value: event.target.value });
    };
  }

  componentWillReceiveProps (props) {
    this.setState({value: props.initialValue});
  }

  render () {
    const value = this.state.value;

    return (
      <div className="form-group optional field_options">
        <Label {...this.props} />
        <div className="col-sm-10">
          <input
            type="text"
            disabled={this.props.disabled}
            name={this.props.model + '[' + this.props.attribute + '][]'}
            id={this.props.modelId + '_' + this.props.attribute + '_'}
            value={value}
            pattern={this.props.pattern}
            onChange={this.handleChange}
            className={'string form-control ' + this.props.disabled + ' ' + this.props.required}
            placeholder={this.props.placeholder} />
          <p className="help-block">
            {this.props.description}
          </p>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}


class TelephoneField extends React.Component {
  constructor (props) {
    super(props);

    this.state = { value: this.props.initialValue };

    this.handleChange = (event) => {
      this.props.handleUpdate(this.props.attribute, this.props.index, event.target.value);
      this.setState({ value: event.target.value });
    };
  }

  componentWillReceiveProps (props) {
    this.setState({value: props.initialValue});
  }

  render () {
    const value = this.state.value;

    return (
      <div className="form-group tel field_options">
        <Label {...this.props} />
        <div className="col-sm-10">
          <input
            type="tel"
            disabled={this.props.disabled}
            name={this.props.model + '[' + this.props.attribute + '][]'}
            id={this.props.modelId + '_' + this.props.attribute + '_'}
            value={value}
            onChange={this.handleChange}
            className={'string tel form-control ' + this.props.disabled + ' ' + this.props.required}
            placeholder={this.props.placeholder} />
          <p className="help-block">
            {this.props.description}
          </p>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

class UrlField extends React.Component {
  constructor (props) {
    super(props);

    this.state = { value: this.props.initialValue };

    this.handleChange = (event) => {
      this.props.handleUpdate(this.props.attribute, this.props.index, event.target.value);
      this.setState({ value: event.target.value });
    };
  }

  componentWillReceiveProps (props) {
    this.setState({value: props.initialValue});
  }

  render () {
    const value = this.state.value;

    return (
      <div className="form-group url field_options">
        <Label {...this.props} />
        <div className="col-sm-10">
          <input
            type="url"
            disabled={this.props.disabled}
            name={this.props.model + '[' + this.props.attribute + '][]'}
            id={this.props.modelId + '_' + this.props.attribute + '_'}
            value={value}
            onChange={this.handleChange}
            className={'string url form-control ' + this.props.disabled + ' ' + this.props.required}
            placeholder={this.props.placeholder} />
          <p className="help-block">
            {this.props.description}
          </p>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

class EmailField extends React.Component {
  constructor (props) {
    super(props);

    this.state = { value: this.props.initialValue };

    this.handleChange = (event) => {
      this.props.handleUpdate(this.props.attribute, this.props.index, event.target.value);
      this.setState({ value: event.target.value });
    };
  }

  componentWillReceiveProps (props) {
    this.setState({value: props.initialValue});
  }

  render () {
    const value = this.state.value;

    return (
      <div className="form-group email field_options">
        <Label {...this.props} />
        <div className="col-sm-10">
          <input
            type="email"
            disabled={this.props.disabled}
            name={this.props.model + '[' + this.props.attribute + '][]'}
            id={this.props.modelId + '_' + this.props.attribute + '_'}
            value={value}
            onChange={this.handleChange}
            className={'string email form-control ' + this.props.disabled + ' ' + this.props.required}
            placeholder={this.props.placeholder} />
          <p className="help-block">
            {this.props.description}
          </p>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

class TimeField extends React.Component {
  constructor (props) {
    super(props);

    this.state = { value: this.props.initialValue };

    this.handleChange = (event) => {
      this.props.handleUpdate(this.props.attribute, this.props.index, event.target.value);
      this.setState({ value: event.target.value });
    };
  }

  componentWillReceiveProps (props) {
    this.setState({value: props.initialValue});
  }

  render () {
    const value = this.state.value;

    return (
      <div className="form-group time">
        <Label {...this.props} />
        <div className="col-sm-10 form-inline">
          <input
            type="time"
            disabled={this.props.disabled}
            name={this.props.model + '[' + this.props.attribute + '][]'}
            id={this.props.modelId + '_' + this.props.attribute + '_'}
            value={value}
            min={this.props.min}
            max={this.props.max}
            onChange={this.handleChange}
            className={'time form-control ' + this.props.disabled + ' ' + this.props.required} />
          <p className="help-block">
            {this.props.description}
          </p>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

class NumberField extends React.Component {
  constructor (props) {
    super(props);

    this.state = { value: this.props.initialValue };

    this.handleChange = (event) => {
      this.props.handleUpdate(this.props.attribute, this.props.index, event.target.value);
      this.setState({ value: event.target.value });
    };
  }

  componentWillReceiveProps (props) {
    this.setState({value: props.initialValue});
  }

  render () {
    const value = this.state.value;

    return (
      <div className="form-group number field_options">
        <Label {...this.props} />
        <div className="col-sm-10">
          <input
            type="number"
            disabled={this.props.disabled}
            name={this.props.model + '[' + this.props.attribute + '][]'}
            id={this.props.modelId + '_' + this.props.attribute + '_'}
            value={value}
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            pattern={this.props.pattern}
            onChange={this.handleChange}
            className={'number form-control ' + this.props.disabled + ' ' + this.props.required}
            placeholder={this.props.placeholder} />
          <p className="help-block">
            {this.props.description}
          </p>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

class CurrencyField extends React.Component {
  constructor (props) {
    super(props);

    this.state = { value: this.props.initialValue };

    this.handleChange = (event) => {
      this.props.handleUpdate(this.props.attribute, this.props.index, event.target.value);
      this.setState({ value: event.target.value });
    };
  }

  componentWillReceiveProps (props) {
    this.setState({value: props.initialValue});
  }

  componentDidMount () {
  }

  render () {
    const value = this.state.value;

    return (
      <div className="form-group number field_options">
        <Label {...this.props} />
        <div className="col-sm-10">
          <div className="input-group col-sm-12">
            <span class="input-group-addon">$</span>
            <input
              type="number"
              disabled={this.props.disabled}
              name={this.props.model + '[' + this.props.attribute + '][]'}
              id={this.props.modelId + '_' + this.props.attribute + '_'}
              value={value}
              step="0.01"
              min={this.props.min}
              max={this.props.max}
              onChange={this.handleChange}
              className={'currency form-control ' + this.props.disabled + ' ' + this.props.required}
              placeholder={this.props.placeholder} />
            <p className="help-block">
              {this.props.description}
            </p>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

class DateField extends React.Component {
  constructor (props) {
    super(props);

    this.state = { value: this.props.initialValue };

    this.handleChange = (event) => {
      this.props.handleUpdate(this.props.attribute, this.props.index, event.target.value);
      this.setState({ value: event.target.value });
    };
  }

  componentWillReceiveProps (props) {
    this.setState({value: props.initialValue});
  }

  render () {
    const value = this.state.value;

    return (
      <div className="form-group date">
        <Label {...this.props} />
        <div className="col-sm-10 form-inline">
          <input
            type="date"
            disabled={this.props.disabled}
            name={this.props.model + '[' + this.props.attribute + '][]'}
            id={this.props.modelId + '_' + this.props.attribute + '_'}
            value={value}
            min={this.props.min}
            max={this.props.max}
            onChange={this.handleChange}
            className={'date form-control ' + this.props.disabled + ' ' + this.props.required} />
          <p className="help-block">
            {this.props.description}
          </p>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

class DatetimeLocalField extends React.Component {
  constructor (props) {
    super(props);

    this.state = { value: this.props.initialValue };

    this.handleChange = (event) => {
      this.props.handleUpdate(this.props.attribute, this.props.index, event.target.value);
      this.setState({ value: event.target.value });
    };
  }

  componentWillReceiveProps (props) {
    this.setState({value: props.initialValue});
  }

  render () {
    const value = this.state.value;

    return (
      <div className="form-group date">
        <Label {...this.props} />
        <div className="col-sm-10 form-inline">
          <input
            type="datetime-local"
            disabled={this.props.disabled}
            name={this.props.model + '[' + this.props.attribute + '][]'}
            id={this.props.modelId + '_' + this.props.attribute + '_'}
            value={value}
            min={this.props.min}
            max={this.props.max}
            onChange={this.handleChange}
            className={'datetime form-control ' + this.props.disabled + ' ' + this.props.required} />
          <p className="help-block">
            {this.props.description}
          </p>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

module.exports = {
  Label: Label,
  TextField: TextField,
  TelephoneField: TelephoneField,
  UrlField: UrlField,
  EmailField: EmailField,
  NumberField: NumberField,
  TimeField: TimeField,
  CurrencyField: CurrencyField,
  DateField: DateField,
  DatetimeLocalField: DatetimeLocalField
};
