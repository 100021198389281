
export function setRecentFilter( filter ){
  return { type: 'SET_RECENT_FILTER', filter };
}

export function setApprovalFilter( filter ){
  return { type: 'SET_APPROVAL_FILTER', filter };
}

export function setAllFilter( filter ){
  return { type: 'SET_ALL_FILTER', filter };
}

export function setArchivedFilter( filter ){
  return { type: 'SET_ARCHIVED_FILTER', filter };
}

export function setEntriesFilter( filter ){
  return { type: 'SET_ENTRIES_FILTER', filter };
}
