import InstancesTable from './approval_instances_table';
import { connect } from 'react-redux';
import AutoComplete from 'material-ui/AutoComplete';
import { setApprovalFilter } from '../../actions/current_user_actions';

// Redux functions
//------------------------------------------------------------------------------
const mapStateToProps = (state) => {
  const { approval_instances } = state;
  const { approval } = state.filters;

  return {
    instances: visibleInstances(approval, approval_instances)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    instanceInputOnChange: (e) => {
      dispatch( setApprovalFilter(e.target.value));
    },
  };
};

const visibleInstances = (filter, instances) => {
  return instances.filter( (instance) => {
    const {fillable_name, form_name, creator_name, instance_token} = instance;

    return  AutoComplete.fuzzyFilter(filter, fillable_name) ||
            AutoComplete.fuzzyFilter(filter, form_name) ||
            AutoComplete.fuzzyFilter(filter, creator_name) ||
            AutoComplete.fuzzyFilter(filter, instance_token);
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(InstancesTable);
