import { PropTypes } from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, TableFooter} from 'material-ui/Table';
import FilterInput from './filter_input';
import { indexOf, find } from 'lodash';
import FlatButton from 'material-ui/FlatButton';
import LeftArrowIcon from 'material-ui/svg-icons/navigation/chevron-left';
import RightArrowIcon from 'material-ui/svg-icons/navigation/chevron-right';
import LeftMaxArrowIcon from 'material-ui/svg-icons/av/skip-previous';
import RightMaxArrowIcon from 'material-ui/svg-icons/av/skip-next';
import Avatar from 'material-ui/Avatar';

const UserTable = ({users, selected, filter, currentPage, maxPage,
                    filterOnChange, rowOnSelect, handlePagerMaxIncrement,
                    handlePagerMaxDecrement,
                    handlePagerIncrement, handlePagerDecrement}) => {
  return (
      <div className="UserTable">
        <Table
          onRowSelection={
            (selectedIndexes) => {
              if(selectedIndexes === 'all'){ return selectedIndexes; }

              const ret = users.map((g, i) => {
                const { id } = g;
                return { id, selected:(indexOf(selectedIndexes, i) !== -1)};
              });

              rowOnSelect(ret);
            }}
          rowOnSelect={rowOnSelect}
          height={'500px'}
          multiSelectable={true}
          fixedHeader={true}
          fixedFooter={true}
          selectable={true}
        >
          <TableHeader>
            <TableRow>
              <TableHeaderColumn colSpan={4}>
                <FilterInput
                  filterTerm={filter}
                  searchText="Search Groups"
                  onChange={filterOnChange}
                />
              </TableHeaderColumn>
            </TableRow>
            <TableRow>
              <TableHeaderColumn></TableHeaderColumn>
              <TableHeaderColumn>First Name</TableHeaderColumn>
              <TableHeaderColumn>Last Name</TableHeaderColumn>
              <TableHeaderColumn>Email</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody deselectOnClickaway={false}>
            { users.map((user) => {
              const { id, first_name, last_name, email, picture } = user;
              const isSelected = (typeof find(selected, { id }) !== 'undefined') ? true : false;
              const userImg = (picture && typeof picture !== 'undefined')?
                <Avatar src={picture} /> :
                <Avatar>
                  {(first_name || '').slice(0,1).toUpperCase()}
                  {(last_name  || '').slice(0,1).toUpperCase()}
                </Avatar>;

              return (
               <TableRow key={id} style={{ height: '55px' }} selected={isSelected}>
                 <TableRowColumn>{userImg}</TableRowColumn>
                 <TableRowColumn>{first_name}</TableRowColumn>
                 <TableRowColumn>{last_name}</TableRowColumn>
                 <TableRowColumn>{email}</TableRowColumn>
               </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableRowColumn colSpan={5}>
                <div className="user-pager">
                  <div className="left-btns">
                    <FlatButton
                      style={{ width: '45px' }}
                      disabled={(currentPage < 1)}
                      onTouchTap={handlePagerMaxDecrement}
                      icon={<LeftMaxArrowIcon />}
                    />
                    <FlatButton
                      style={{ width: '45px' }}
                      disabled={(currentPage < 1)}
                      onTouchTap={handlePagerDecrement}
                      icon={<LeftArrowIcon />}
                    />
                  </div>
                  <div className="currentPage">
                    page {currentPage + 1} / {maxPage + 1}
                  </div>
                  <div
                    style={{marginRight: '50px'}}
                    className="right-btns">
                    <FlatButton
                      style={{ width: '45px' }}
                      disabled={(currentPage >= maxPage)}
                      onTouchTap={handlePagerIncrement}
                      icon={<RightArrowIcon />}
                    />
                    <FlatButton
                      style={{ width: '45px' }}
                      disabled={(currentPage >= maxPage)}
                      onTouchTap={() => handlePagerMaxIncrement(maxPage)}
                      icon={<RightMaxArrowIcon />}
                    />
                  </div>
                </div>
              </TableRowColumn>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
  );
};

UserTable.propTypes = {
  currentPage: PropTypes.number.isRequired,
  maxPage: PropTypes.number.isRequired,
  handlePagerMaxIncrement: PropTypes.func.isRequired,
  handlePagerMaxDecrement: PropTypes.func.isRequired,
  handlePagerDecrement: PropTypes.func.isRequired,
  handlePagerIncrement: PropTypes.func.isRequired,
  filterOnChange: PropTypes.func.isRequired,
  filter:PropTypes.string,
  rowOnSelect: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    id:  PropTypes.number.isRequired,
    first_name:  PropTypes.string,
    last_name:  PropTypes.string,
    email:  PropTypes.string.isRequired,
    photo_url:  PropTypes.string,
  })).isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape({
    id:  PropTypes.number.isRequired,
    first_name:  PropTypes.string,
    last_name:  PropTypes.string,
    email:  PropTypes.string.isRequired,
    photo_url:  PropTypes.string,
  })).isRequired
};


export default UserTable;
