// A list of recent instances, instances needing approval and
// instances that the approver should approve
import {Tabs, Tab} from 'material-ui/Tabs';
import { connect } from 'react-redux';
import { PropTypes } from 'react';

// Icons
import RecentIcon from 'material-ui/svg-icons/action/history';
import ApprovalIcon from 'material-ui/svg-icons/content/inbox';
import ArchivedIcon from 'material-ui/svg-icons/content/archive';
import EntryIcon from 'material-ui/svg-icons/notification/event-note';

// Tables
import AllUserInstances    from './all_user_instances';
import ApprovalInstances   from './approval_instances';
import EntriesList   from './entries';
import RecentUserInstances from './recent_user_instances';

const UserInstancesSwitcher = ({approval_instances_count, entries_count, recent_instances_count, all_instances_count}) => {
  return (
    // Only show tabs if there is something to show
    ([recent_instances_count, entries_count, approval_instances_count, all_instances_count].some( n => n > 0)) &&
    <section className="UserSelectorInfinite">
      <Tabs>
        {(recent_instances_count > 0) && <Tab
          icon={<RecentIcon />}
          label="Recent Instances">
          <RecentUserInstances />
        </Tab>}
        {((entries_count > 0)) &&
        <Tab
          icon={<EntryIcon />}
          label="Lottery Entries">
          <EntriesList />
        </Tab>}
        {(approval_instances_count > 0) &&
        <Tab
          icon={<ApprovalIcon />}
          label="Instances To Approve">
          <ApprovalInstances />
        </Tab>}
        {(all_instances_count > 0) && <Tab
          icon={<ArchivedIcon />}
          label="All Instances">
          <AllUserInstances />
        </Tab>}
      </Tabs>
    </section>
  );
};

UserInstancesSwitcher.propTypes = {
  approval_instances_count: PropTypes.number,
  entries_count: PropTypes.number,
  recent_instances_count: PropTypes.number,
  all_instances_count: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    approval_instances_count: state.approval_instances.length,
    entries_count: state.entries.length,
    recent_instances_count: state.recent_instances.length,
    all_instances_count: state.all_instances.length,
  };
};

export default connect(mapStateToProps)(UserInstancesSwitcher);
