import { connect } from 'react-redux';
import UserTable from './user_table';
import { updateUserFilter, setUsersSelected } from '../../actions/user_role_actions';
import { increasePage, decreasePage, resetPager, setCurrentPage } from '../../actions/pager_actions';
import AutoComplete from 'material-ui/AutoComplete';

const { fuzzyFilter } = AutoComplete;

const getVisibleUsers = (users, filter) => {
  if(typeof filter === 'undefined' || filter === ''){
    return users;
  }

  return users.filter( (user) => {
    const { first_name, last_name, email } = user;
    return  fuzzyFilter(filter, first_name || '')  ||
            fuzzyFilter(filter, last_name  || '')  ||
            fuzzyFilter(filter, email      || '');
  });
};

const getVisiblePage = (users, currentPage, itemsPerPage) => {
  const start = currentPage * itemsPerPage;
  const end = start + itemsPerPage + 1;

  return users.slice(start, end);
};

const mapStateToProps = (state) => {
  const { list, filter, selected} = state.users;
  const { itemsPerPage, currentPage } = state.pager;
  const visibleUsers = getVisibleUsers(list, filter);
  const maxPage = (visibleUsers.length % itemsPerPage === 0) ?
    (visibleUsers.length / itemsPerPage) - 1 :
    parseInt(visibleUsers.length / itemsPerPage);
  return {
    currentPage,
    maxPage,
    filter,
    selected,
    users: getVisiblePage(visibleUsers, currentPage, itemsPerPage)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterOnChange: (e) => {
      const filterText = e.target.value;
      dispatch(resetPager());
      dispatch(updateUserFilter(filterText));
    },
    rowOnSelect: (selectedRowIndexes) => {
      dispatch(setUsersSelected(selectedRowIndexes));
    },
    handlePagerIncrement: () => {
      dispatch(increasePage());
    },
    handlePagerMaxIncrement: (maxPage) => {
      dispatch(setCurrentPage(maxPage));
    },
    handlePagerDecrement: () => {
      dispatch(decreasePage());
    },
    handlePagerMaxDecrement: () => {
      dispatch(resetPager());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
