// A Table for instances that are archived and unarchived

import { PropTypes } from 'react';
import Subheader from 'material-ui/Subheader';
import SearchIcon from 'material-ui/svg-icons/action/search';
import IconButton from 'material-ui/IconButton';
import TextField from 'material-ui/TextField';
import ApprovalIcon from 'material-ui/svg-icons/content/inbox';
import LinkIcon from 'material-ui/svg-icons/content/link';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import { grey300, blue500, lightBlack, lime600 } from 'material-ui/styles/colors';

// Styles
//------------------------------------------------------------------------------
const headerStyles = {
  color: lightBlack,
  fontSize: '18px'
};

const miniIconStyles = {
  height: '35px',
  width: '35px',
  position: 'relative',
  top: '10px',
  left: '-5px'
};

const inlineSearchStyles = {
  float: 'right',
  textAlign: 'right',
  fontWeight: 400,
  marginRight: '1em'
};

// Component
//------------------------------------------------------------------------------
const InstancesTable = ({ instances, instanceInputOnChange, hideTable }) => {
  if( hideTable ){ return null; }

  return (<div className="InstancesTable">
    <Table
      height="500px"
      fixedHeader={true}
      fixedFooter={true}
      showRowHover={true}
      selectable={false}
      showCheckboxes={false}
    >
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        enableSelectAll={false}
      >
        <TableRow>
          <TableHeaderColumn colSpan="5">
            <Subheader style={headerStyles}>
              <ApprovalIcon color={lime600} style={miniIconStyles} />
              Instances You Can Approve
              <TextField
                onChange={instanceInputOnChange}
                style={inlineSearchStyles}
                underlineFocusStyle= {{ borderColor: lime600 }}
                hintText={<span>
                  <SearchIcon
                    color={grey300}
                    style={{
                      height: '15px',
                      width: '15px',
                      position: 'relative',
                      top: '2px',
                      left: '-2px'
                    }}
                  />
                  Find Instance
                </span>}
              />
            </Subheader>
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody
        displayRowCheckbox={false}
      >
        <TableRow>
          <TableRowColumn>Creator</TableRowColumn>
          <TableRowColumn>Fillable</TableRowColumn>
          <TableRowColumn>Form</TableRowColumn>
          <TableRowColumn>Current Step</TableRowColumn>
          <TableRowColumn>Approved</TableRowColumn>
          <TableRowColumn>Updated</TableRowColumn>
          <TableRowColumn>Token</TableRowColumn>
          <TableRowColumn style={{width: '100px'}}>
            {"  "}
          </TableRowColumn>
        </TableRow>
        { instances.map( (instance) => {
          const { creator_name, updated_at, current_step, instance_token, percentage, path, form_name, fillable_name, archived, archived_at } = instance;
          return (<TableRow key={instance_token}>
            <TableRowColumn>{creator_name}</TableRowColumn>
            <TableRowColumn>{fillable_name}</TableRowColumn>
            <TableRowColumn>{form_name}</TableRowColumn>
            <TableRowColumn>{current_step}</TableRowColumn>
            <TableRowColumn>{((percentage && percentage + ' %') || '0%')}</TableRowColumn>
            <TableRowColumn>{updated_at}</TableRowColumn>
            <TableRowColumn>{instance_token}</TableRowColumn>
            <TableRowColumn style={{width: '100px' }}>
              <a href={path}>
                <IconButton
                  iconStyle={{ color: lime600 }}
                  style={{float: 'right'}}
                >
                  <LinkIcon />
                </IconButton>
              </a>
            </TableRowColumn>
          </TableRow>);
        })}
      </TableBody>
    </Table>
  </div>);
};

InstancesTable.propTypes = {
  instanceInputOnChange: PropTypes.func.isRequired,
  new_instance_path: PropTypes.string,
  currentStep: PropTypes.number,
  hideNewInstanceButton: PropTypes.bool,
  hideTable: PropTypes.bool,
  instances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      updated_at: PropTypes.string,
      revision: PropTypes.number,
      percentage: PropTypes.number,
      instance_token: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    })
  )
};

InstancesTable.defaultProps = {
  hideNewInstanceButton: false,
  hideTable: false,
};


export default InstancesTable;
