import InstancesTable from './all_instances_table';
import { connect } from 'react-redux';
import AutoComplete from 'material-ui/AutoComplete';
import { setAllFilter, setArchivedFilter } from '../../actions/current_user_actions';

// Redux functions
//------------------------------------------------------------------------------
const mapStateToProps = (state) => {
  const { all_instances } = state;
  const { all, archived } = state.filters;

  return {
    hideNewInstanceButton: true,
    instances: visibleInstances(all, archived, all_instances)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    instanceInputOnChange: (e) => {
      dispatch(setAllFilter(e.target.value));
    },
    archiveToggleOnChange: (e, value) => {
      dispatch(setArchivedFilter(value));
    },
  };
};

const filterInstance = ( instance, filter ) => {
  const {fillable_name, form_name, instance_token} = instance;
  return  AutoComplete.fuzzyFilter(filter, fillable_name) ||
          AutoComplete.fuzzyFilter(filter, form_name) ||
          AutoComplete.fuzzyFilter(filter, instance_token);
};

const visibleInstances = (filter, show_archived, instances) => {
  // Avoid running instance filter for default states
  if (filter === '' && show_archived){ return instances; }
  if (filter === '' && !show_archived){ return instances.filter( instance => !instance.archived); }

  return instances.filter((instance) => {
    return show_archived ?
      filterInstance(instance, filter) :
      !instance.archived && filterInstance(instance, filter);
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(InstancesTable);
